import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { AvatarComponent } from '../../atoms/avatar';
import { LogoComponent } from '../../atoms/logo';
import { NavbarComponent } from '../../atoms/navbar';
import { InputComponent } from '../../atoms/input';
import {
  BBArrowDownFilledIcon,
  BbCloseIcon,
  BBSearchIcon,
} from '../../quarks/icons';
import { HeaderStyle } from './style';

export const HeaderComponent = (props) => {
  const inputComponentRef = useRef();
  const search_by_query = localStorage.getItem('search_by_query');

  const [focusInputOnRender, setFocusInputOnRender] = useState(
    search_by_query ? true : false
  );

  useEffect(() => {
    if (search_by_query) {
      props.onChange({ target: { value: search_by_query } });
    }
  }, [])

  const [isOpen, setIsOpen] = useState(props.isOpen);

  const handleClick = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    props.onLogoClick({ isOpen: newIsOpen });
  };

  return (
    <HeaderStyle {...props}>
      <NavbarComponent>
        <div className="navbar_area">
          <div className="logo_area" onClick={handleClick}>
            <LogoComponent />
            <BBArrowDownFilledIcon isUpDirection={isOpen} />
          </div>
          <div className="search_area">
            <InputComponent
              ref={inputComponentRef}
              placeholder="Digite aqui e procure em todo o conteúdo"
              icon={
                props.inputValue.length > 0 ? (
                  <button
                    className="search_input_clear_icon"
                    onClick={props.onClearInput}
                  >
                    <BbCloseIcon />
                  </button>
                ) : (
                  <BBSearchIcon />
                )
              }
              value={props.inputValue}
              onChange={props.onChange}
              onFocus={props.onInputFocus}
              autoFocus={focusInputOnRender}
            />
          </div>
          <div className="user_info">
            <AvatarComponent image={props.avatar} userName={props.userName} />
          </div>
        </div>
      </NavbarComponent>
    </HeaderStyle>
  );
};

HeaderComponent.propTypes = {
  avatar: PropTypes.string,
  userName: PropTypes.string,
  onChange: PropTypes.func,
  onLogoClick: PropTypes.func,
  onInputFocus: PropTypes.func,
  isOpen: PropTypes.bool,
};
