import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getGiftCardOfLocalStorage } from '../services/cache/giftCard';
import { getIndicationCoupon } from '../services/cache/indicationCoupon';
import { authApi, planApi } from '../services/clients';
import { useIsDesktopOrTabletScreen } from '../services/hooks/useIsDesktopOrTabletScreen';
import { useTrack } from '../services/hooks/useTrack';
import {
  clearUserTokensOfLocalStorage,
  getAnonymousToken,
  getUserToken,
  handleSetLoginToken,
} from '../services/tokenService';

const GET_USER_DATA = gql`
  query GetUser {
    getUser {
      name
      auth {
        email
      }
      _id
      actuation_area_id
      college
      completed_registration
      country_dial
      country_phone
      created_at
      crm_number
      document
      document_type
      graduation_year
      image_profile_url
      indication_code
      is_professional
      phone_number
      state_id
      token
      updated_at
      indicator_code
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation UserLogin($password: String!, $email: String!, $anonymousToken: String) {
    login(password: $password, email: $email, anonymousToken: $anonymousToken) {
      name
      _id
      token
    }
  }
`;

const UserContextAplication = createContext();

const UserAplicationProvider = ({ children }) => {
  const [getUser, {data: user_data, loading: user_loading}] = useLazyQuery(GET_USER_DATA, {
    fetchPolicy: "no-cache",
  })

  const [
    userLogin,
    { data: login_data, loading: login_loading, error: login_error },
  ] = useMutation(LOGIN_MUTATION);

  const history = useHistory();

  const isDesktopOrLaptop = useIsDesktopOrTabletScreen()

  const [userProperties, setUserProperties] = useState(null);
  const [activePlan, setActivePlan] = useState(null);

  const {trackEvent} = useTrack();

  async function handleLogin(payload) {
    try {
      const response = await userLogin({
        variables: {
          email: payload.email,
          password: payload.password,
          anonymousToken: getAnonymousToken()
        },
      });
      await handleSetLoginToken(response.data.login.token);
      localStorage.setItem('BBTIME', new Date().getTime());

      if (location.state?.from) {
        history.push(location.state.from);
      } else {
        if(!getIndicationCoupon() && !getGiftCardOfLocalStorage() && isDesktopOrLaptop){
          history.push('/app')
        } else {
          if(getGiftCardOfLocalStorage()){
            history.push('/giftcard')
          } else {
            history.push('/home')
          }
        }
      }
      trackEvent("Fez login web");
      return true;
    } catch (error) {
      throw Error(error);
    }
  }

  function forceUserLoggout() {
    if (getUserToken()) {
      handleClearUserData();
      history.push('/signin');
    }
  }

  const location = useLocation();
  
  async function handleGetUser(forceFetch = false) {
    if (forceFetch || !userProperties) {
      try {
        const response = await getUser();
        setUserProperties(response.data.getUser);
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function handleGetUserPlan(forceFetch = false) {
    if (forceFetch || !activePlan || (activePlan?.level_access < 3)) {
      try {
        const userPlan = await planApi.getActivePlan();
        setActivePlan(userPlan);
      } catch (error) {
        console.log(error.response.data);
      }
    }
  }

  async function fetchUserData(forceFetch = false) {
    if(getUserToken()) {

      await handleGetUser(forceFetch);
      await handleGetUserPlan(forceFetch);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [location.key]);

  async function handleClearUserData() {
    clearUserTokensOfLocalStorage();
    setUserProperties(null);
  }

  const context = {
    userProperties,
    activePlan,
    setActivePlan,
    handleGetUser,
    handleClearUserData,
    handleGetUserPlan,
    handleLogin,
    forceUserLoggout,
    fetchUserData,
  };

  return (
    <>
      <UserContextAplication.Provider value={context}>
        {children}
      </UserContextAplication.Provider>
    </>
  );
};

export { UserAplicationProvider, UserContextAplication };

export function useUser() {
  const context = useContext(UserContextAplication);
  return context;
}
