import { colors } from "../theme";

export const BbDrugIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M3.18199 9.37831L9.37795 3.18235C10.3669 2.20695 11.7014 1.66223 13.0905 1.66702C14.4795 1.67182 15.8103 2.22575 16.7924 3.20796C17.7746 4.19017 18.3285 5.52095 18.3333 6.90997C18.338 8.299 17.7933 9.63354 16.8178 10.6225L10.6221 16.8182C9.63317 17.7936 8.29863 18.3384 6.90961 18.3336C5.52058 18.3289 4.1898 17.775 3.20759 16.7928C2.22538 15.8106 1.67145 14.4799 1.66666 13.0908C1.66186 11.7018 2.20658 10.3673 3.18199 9.37831ZM4.4264 10.6227C3.98931 11.0559 3.67472 11.597 3.51464 12.1912C3.35455 12.7854 3.35468 13.4113 3.515 14.0054L8.76429 8.76493L12.4799 12.4805L15.5733 9.37826C16.227 8.72106 16.5934 7.83147 16.5921 6.90455C16.5909 5.97763 16.2221 5.08903 15.5667 4.4336C14.9113 3.77816 14.0227 3.40939 13.0957 3.40814C12.1688 3.4069 11.2792 3.77327 10.622 4.42694L4.4264 10.6227Z" fill={props.secondaryColor || colors.disableGray}/>
        <path d="M3.50759 13.9546L8.76463 8.64918L12.5185 12.4272L9.00377 15.6759C5.61922 18.431 3.50759 13.9546 3.50759 13.9546Z" fill={props.primaryColor || colors.primary}/>
    </svg>
);

export const BbRoutineIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8333 6.66699C16.3 6.66699 16.6666 7.02533 16.6666 7.50033C16.6666 7.72134 16.5788 7.9333 16.4225 8.08958C16.2663 8.24586 16.0543 8.33366 15.8333 8.33366C15.3583 8.33366 15 7.95866 15 7.50033C15 7.02533 15.3583 6.66699 15.8333 6.66699ZM1.66663 1.66699V9.16699C1.66663 11.6337 3.49163 13.7503 5.94996 14.092C6.46663 16.6003 8.68329 18.3337 11.25 18.3337C11.9613 18.3337 12.6656 18.1936 13.3228 17.9213C13.98 17.6491 14.5771 17.2501 15.0801 16.7472C15.5831 16.2442 15.9821 15.647 16.2543 14.9899C16.5265 14.3327 16.6666 13.6283 16.6666 12.917V9.84199C17.6333 9.49199 18.3333 8.57533 18.3333 7.50033C18.3333 6.83728 18.0699 6.2014 17.6011 5.73256C17.1322 5.26372 16.4963 5.00033 15.8333 5.00033C15.1703 5.00033 14.5344 5.26372 14.0655 5.73256C13.5967 6.2014 13.3333 6.83728 13.3333 7.50033C13.3333 8.57533 14.0333 9.50033 15 9.84199V12.842C15 14.9253 13.3333 16.592 11.25 16.592C9.58329 16.592 8.18329 15.5837 7.68329 14.0837C9.99996 13.5837 11.6666 11.5003 11.6666 9.16699V1.66699H9.99996V4.16699V9.16699C9.99996 10.051 9.64877 10.8989 9.02365 11.524C8.39853 12.1491 7.55068 12.5003 6.66663 12.5003C5.78257 12.5003 4.93472 12.1491 4.3096 11.524C3.68448 10.8989 3.33329 10.051 3.33329 9.16699V4.16699V1.66699H1.66663Z" fill={props.secondaryColor || colors.disableGray}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.33337 1.66699H4.00004C4.55233 1.66699 5.00004 2.11471 5.00004 2.66699V3.16699C5.00004 3.71928 4.55233 4.16699 4.00004 4.16699H3.33337V1.66699ZM8.33337 2.66699C8.33337 2.11471 8.78109 1.66699 9.33337 1.66699H10V4.16699H9.33337C8.78109 4.16699 8.33337 3.71928 8.33337 3.16699V2.66699ZM18.3334 7.50033C18.3334 8.57533 17.6334 9.49199 16.6667 9.84199C15.9192 10.1106 15.2441 9.95392 15 9.84199C14.0334 9.50033 13.3334 8.57533 13.3334 7.50033C13.3334 6.83728 13.5968 6.2014 14.0656 5.73256C14.5344 5.26372 15.1703 5.00033 15.8334 5.00033C16.4964 5.00033 17.1323 5.26372 17.6011 5.73256C18.07 6.2014 18.3334 6.83728 18.3334 7.50033ZM15.8334 6.66699C16.3 6.66699 16.6667 7.02533 16.6667 7.50033C16.6667 7.72134 16.5789 7.9333 16.4226 8.08958C16.2663 8.24586 16.0544 8.33366 15.8334 8.33366C15.3584 8.33366 15 7.95866 15 7.50033C15 7.02533 15.3584 6.66699 15.8334 6.66699Z" fill={props.primaryColor || colors.primary}/>
    </svg>
)

export const BbHomeIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.974398 3.19474C0.373236 3.58973 0 4.30588 0 5.08443V18.0313C0 19.2469 0.895431 20.2324 2 20.2324H18C19.1046 20.2324 20 19.2469 20 18.0313V5.08443C20 4.30588 19.6268 3.58973 19.0256 3.19474C18.9404 3.13874 18.8463 3.09813 18.7506 3.06315L11.1439 0.284925C11.0771 0.260554 11.0123 0.231645 10.9474 0.202731C10.9191 0.190144 10.8908 0.177557 10.8624 0.165342C10.3497 -0.0551144 9.65034 -0.0551137 9.13757 0.165343C9.10916 0.177557 9.0809 0.190145 9.05265 0.202732C8.98775 0.231646 8.92285 0.260555 8.85612 0.284926L1.24945 3.06315C1.15367 3.09813 1.05961 3.13875 0.974398 3.19474ZM10 2.23238L2 5.28775V18.0313H18V5.28775L10 2.23238Z" fill={props.secondaryColor || colors.disableGray}/>
    <rect x="3" y="6.23242" width="5" height="5" fill={props.primaryColor || colors.primary}/>
    <rect x="3" y="12.2324" width="5" height="5" fill={props.primaryColor || colors.primary}/>
    <rect x="9" y="6.23242" width="5" height="5" fill={props.primaryColor || colors.primary}/>
    <rect x="15" y="6.23242" width="2" height="5" fill={props.primaryColor || colors.primary}/>
    <rect x="9" y="12.2324" width="5" height="5" fill={props.primaryColor || colors.primary}/>
    <rect x="15" y="12.2324" width="2" height="5" fill={props.primaryColor || colors.primary}/>
    </svg>
)

export const BbAvatarIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M11.6667 16.6667V15.625C11.6667 14.2417 8.88337 13.5417 7.50004 13.5417C6.11671 13.5417 3.33337 14.2417 3.33337 15.625V16.6667H11.6667ZM7.50004 8.33333C6.94751 8.33333 6.4176 8.55283 6.0269 8.94353C5.6362 9.33423 5.41671 9.86413 5.41671 10.4167C5.41671 10.6903 5.47059 10.9612 5.57529 11.2139C5.67999 11.4667 5.83345 11.6964 6.0269 11.8898C6.4176 12.2805 6.94751 12.5 7.50004 12.5C7.77363 12.5 8.04454 12.4461 8.2973 12.3414C8.55006 12.2367 8.77972 12.0833 8.97318 11.8898C9.16664 11.6964 9.32009 11.4667 9.42479 11.2139C9.52949 10.9612 9.58337 10.6903 9.58337 10.4167C9.58337 10.1431 9.52949 9.87217 9.42479 9.61941C9.32009 9.36665 9.16664 9.13698 8.97318 8.94353C8.77972 8.75007 8.55006 8.59661 8.2973 8.49192C8.04454 8.38722 7.77363 8.33333 7.50004 8.33333ZM10.8334 5V5.83333H15.8334V5H10.8334ZM10.8334 6.66667V7.5H15.8334V6.66667H10.8334ZM10.8334 8.33333V9.16667H14.1667V8.33333H10.8334Z" fill={props.primaryColor || colors.primary}/>
    <path d="M16.6666 1.66699H3.33329C2.42496 1.70033 1.69996 2.42533 1.66663 3.33366V16.667C1.69996 17.5753 2.42496 18.3003 3.33329 18.3337H16.6666C17.575 18.3003 18.3 17.5753 18.3333 16.667V3.33366C18.3 2.42533 17.575 1.70033 16.6666 1.66699ZM16.6666 16.667H3.33329V3.33366H16.6666V16.667Z" fill={props.secondaryColor || colors.disableGray}/>
    </svg>
)

export const BbChatIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.3333 6.66634V18.333L15 14.9997H5.83333C5.61232 14.9997 5.40036 14.9119 5.24408 14.7556C5.0878 14.5993 5 14.3874 5 14.1663V13.333H16.6667V5.83301H17.5C17.721 5.83301 17.933 5.92081 18.0893 6.07709C18.2455 6.23337 18.3333 6.44533 18.3333 6.66634Z" fill={props.secondaryColor || colors.disableGray}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.91663 1.66699H12.9166C13.2481 1.66699 13.5661 1.79869 13.8005 2.03311C14.0349 2.26753 14.1666 2.58547 14.1666 2.91699V10.417C14.1666 10.7485 14.0349 11.0665 13.8005 11.3009C13.5661 11.5353 13.2481 11.667 12.9166 11.667H4.16663L1.66663 14.167V2.91699C1.66663 2.58547 1.79832 2.26753 2.03274 2.03311C2.26716 1.79869 2.58511 1.66699 2.91663 1.66699ZM11.6666 5.41699H4.16663V4.16699H11.6666V5.41699ZM11.6666 6.04199H4.16663V7.29199H11.6666V6.04199ZM4.16663 9.16699H9.16663V7.91699H4.16663V9.16699Z" fill={props.primaryColor || colors.primary}/>
    </svg>
)

export const BbSmartphoneIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M13.75 2.5H17.9167C18.2482 2.5 18.5661 2.6317 18.8005 2.86612C19.035 3.10054 19.1667 3.41848 19.1667 3.75V6.25C19.1667 6.58152 19.035 6.89946 18.8005 7.13388C18.5661 7.3683 18.2482 7.5 17.9167 7.5H15L12.5 10V7.5V3.75C12.5 3.41848 12.6317 3.10054 12.8661 2.86612C13.1005 2.6317 13.4185 2.5 13.75 2.5Z" fill={props.primaryColor || colors.primary}/>
    <path d="M9.16663 14.1667V5.83333H3.33329V14.1667H9.16663ZM9.16663 2.5C9.60865 2.5 10.0326 2.67559 10.3451 2.98816C10.6577 3.30072 10.8333 3.72464 10.8333 4.16667V15.8333C10.8333 16.2754 10.6577 16.6993 10.3451 17.0118C10.0326 17.3244 9.60865 17.5 9.16663 17.5H3.33329C2.40829 17.5 1.66663 16.75 1.66663 15.8333V4.16667C1.66663 3.72464 1.84222 3.30072 2.15478 2.98816C2.46734 2.67559 2.89127 2.5 3.33329 2.5H9.16663Z" fill={props.secondaryColor || colors.disableGray}/>
    </svg>
)

export const BbExitIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M20 20H17.6467V1.81867H2.35333V13.7333L0 13.3547V0H20V20ZM2.35333 20H0V17.0667L2.35333 17.448V20Z" fill={props.secondaryColor || colors.disableGray}/>
    <path d="M10.988 6.51861C10.5212 6.56759 10.0536 6.43408 9.68304 6.14603C9.31247 5.85798 9.06772 5.43772 9.00004 4.97328C9.06738 4.50936 9.31241 4.08979 9.68336 3.80318C10.0543 3.51657 10.5221 3.38536 10.988 3.43728C11.4554 3.38445 11.9251 3.51503 12.2981 3.80153C12.6712 4.08802 12.9185 4.50808 12.988 4.97328C12.9191 5.43944 12.6723 5.86079 12.2994 6.14891C11.9265 6.43703 11.4565 6.56953 10.988 6.51861ZM7.41204 17.2093L0.436035 16.1426L0.836035 14.5973L5.7187 15.3706L7.30537 9.12661L5.51737 9.67194V12.2666H3.52937V8.66661L8.70537 6.97328L9.49337 6.89994C9.81359 6.89631 10.1306 6.96379 10.4216 7.09752C10.7126 7.23126 10.9703 7.4279 11.176 7.67328L12.188 8.89994C12.7117 9.5164 13.3706 10.0036 14.1135 10.3237C14.8563 10.6438 15.663 10.7881 16.4707 10.7453V12.2666C14.4736 12.3067 12.5282 11.6293 10.988 10.3573L10.4 12.6666L12.4827 14.2026V19.9999H10.4947V15.3639L8.40004 13.8266L7.41204 17.2079V17.2093Z" fill={props.primaryColor || colors.primary}/>
    </svg>
)

export const BbPopupIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21273 0H6.78726C7.1089 0 7.41737 0.124518 7.6448 0.34616C7.87223 0.567803 8 0.868415 8 1.18187L8 7L13.822 7C14.1355 7 14.4361 7.12777 14.6577 7.3552C14.8794 7.58263 15.0039 7.8911 15.0039 8.21273V13.7873C15.0039 14.1089 14.8794 14.4174 14.6577 14.6448C14.4361 14.8722 14.1355 15 13.822 15H3.18187C2.86842 15 2.5678 14.8722 2.34616 14.6448C2.12452 14.4174 2 14.1089 2 13.7873L2.00048 13.0003L1.21273 13.0005C0.891097 13.0005 0.582633 12.876 0.355202 12.6544C0.12777 12.4327 0 12.1321 0 11.8187V1.18187C0 0.868415 0.12777 0.567803 0.355202 0.34616C0.582633 0.124518 0.891097 0 1.21273 0ZM7 7H3.18525C2.8718 7 2.57119 7.12777 2.34955 7.3552C2.1279 7.58263 2.00339 7.8911 2.00339 8.21273L2.00169 11H1V2H7V7ZM13.0039 8L4.00391 8L4.00391 14L13.0039 14V8Z" fill="black"/>
    </svg>
)

export const BbResumeIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13 13" fill="none">
        <path d="M3.16768 5.06836C2.81781 5.06836 2.53418 5.35199 2.53418 5.70186C2.53418 6.05173 2.81781 6.33536 3.16768 6.33536H9.50268C9.85255 6.33536 10.1362 6.05173 10.1362 5.70186C10.1362 5.35199 9.85255 5.06836 9.50268 5.06836H3.16768ZM3.16768 6.96886C2.81781 6.96886 2.53418 7.25249 2.53418 7.60236C2.53418 7.95223 2.81781 8.23586 3.16768 8.23586H9.50268C9.85255 8.23586 10.1362 7.95223 10.1362 7.60236C10.1362 7.25249 9.85255 6.96886 9.50268 6.96886H3.16768ZM3.16768 8.86936C2.81781 8.86936 2.53418 9.15299 2.53418 9.50286C2.53418 9.85273 2.81781 10.1364 3.16768 10.1364H7.33068C7.68055 10.1364 7.96418 9.85273 7.96418 9.50286C7.96418 9.15299 7.68055 8.86936 7.33068 8.86936H3.16768Z" fill={props.secondaryColor || colors.disableGray}/>
        <path d="M11.403 0H1.267C0.576483 0.02534 0.02534 0.576477 0 1.26699V11.403C0.02534 12.0935 0.576483 12.6447 1.267 12.67H11.403C12.0935 12.6447 12.6447 12.0935 12.67 11.403V1.26699C12.6447 0.576477 12.0935 0.02534 11.403 0ZM11.403 10.403C11.403 10.9553 10.9553 11.403 10.403 11.403H2.267C1.71471 11.403 1.267 10.9553 1.267 10.403V4.801C1.267 4.24871 1.71471 3.801 2.267 3.801H10.403C10.9553 3.801 11.403 4.24872 11.403 4.801V10.403Z" fill={props.primaryColor || colors.primary}/>
    </svg>
)

export const BbTableIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M13.5 0H1.5C0.675 0 0 0.675 0 1.5V13.5C0 14.325 0.675 15 1.5 15H13.5C14.325 15 15 14.325 15 13.5V1.5C15 0.675 14.325 0 13.5 0ZM6 13.5H2V11H6V13.5ZM6 10H2V7H6V10ZM6 6H2V3H6V6ZM11 13.5H7V11H11V13.5ZM11 10H7V7H11V10ZM11 6H7V3H11V6ZM14 13.5H12V11H14V13.5ZM14 10H12V7H14V10ZM14 6H12V3H14V6Z" fill={props?.color || "black"}/>
    </svg>
)

export const BbCalculatorIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 10 13" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 1.3C0 0.58203 0.58203 0 1.3 0H7.8C8.51797 0 9.1 0.58203 9.1 1.3V11.7C9.1 12.418 8.51797 13 7.8 13L1.3 13C0.582029 13 0 12.418 0 11.7V1.3ZM7.8 11.7V10.4H6.5V11.7H7.8ZM7.8 9.1V7.8H6.5V9.1H7.8ZM7.8 6.5V5.2H6.5V6.5H7.8ZM7.8 3.9V1.3H1.3V3.9H7.8ZM1.3 6.5V5.2H2.6V6.5H1.3ZM1.3 9.1V7.8H2.6V9.1H1.3ZM1.3 11.7V10.4H2.6V11.7H1.3ZM3.9 11.7H5.2V10.4H3.9V11.7ZM3.9 9.1H5.2V7.8H3.9V9.1ZM5.2 5.2V6.5H3.9V5.2H5.2Z" fill={props.primaryColor || colors.primary}/>
    </svg>
)

export const BbCloseIcon = props => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z" fill="#8774FF"/>
    </svg>
)

export const BBArrowRightIcon = ({height = 8, width = 5}) => (
    <svg width={width} height={height} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.88125 0L0 0.88125L2.8625 3.75L0 6.61875L0.88125 7.5L4.63125 3.75L0.88125 0Z" fill="#8774FF"/>
    </svg>
)

export const BBArrowSeeAll = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
        <path d="M6.41983 7.92003L-0.000155142 1.42003L1.41984 2.60105e-05L9.33984 7.92003L1.41984 15.84L-0.000156278 14.42L6.41983 7.92003Z" fill="white"/>
    </svg>
)

export const BBArrowDownFilledIcon = ({isUpDirection = false}) => (
    <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: isUpDirection ? 'rotate(180deg)' : ''}}>
        <path fillRule="evenodd" clipRule="evenodd" d="M6 9.53674e-07L3 4L-6.51683e-07 1.21594e-06L6 9.53674e-07Z" fill="#8774FF"/>
    </svg>
)

export const BBArrowDownIcon = ({isUpDirection = false, color = "#999"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none" style={{transform: isUpDirection ? 'rotate(180deg)' : ''}}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.88125L7.11875 -3.85207e-08L4.25 2.8625L1.38125 -2.89315e-07L0.5 0.88125L4.25 4.63125L8 0.88125Z" fill={color}/>
    </svg>
)

export const BBSearchIcon = props => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.33608 0C5.48608 0 6.58898 0.456836 7.40216 1.27001C8.21533 2.08318 8.67216 3.18608 8.67216 4.33608C8.67216 5.4101 8.27858 6.39739 7.6315 7.15787L7.81162 7.33799L8.33862 7.33799C8.33862 7.33799 10.8194 9.81874 11.6741 10.6734C12.5288 11.5281 11.5281 12.5288 10.6734 11.6741C9.81874 10.8194 7.33799 8.33862 7.33799 8.33862L7.33799 7.81162L7.15787 7.6315C6.39739 8.27858 5.4101 8.67216 4.33608 8.67216C3.18608 8.67216 2.08318 8.21533 1.27001 7.40216C0.456836 6.58898 0 5.48608 0 4.33608C0 3.18608 0.456836 2.08318 1.27001 1.27001C2.08318 0.456836 3.18608 0 4.33608 0ZM4.33608 1.33418C2.66836 1.33418 1.33418 2.66836 1.33418 4.33608C1.33418 6.00381 2.66836 7.33799 4.33608 7.33799C6.00381 7.33799 7.33799 6.00381 7.33799 4.33608C7.33799 2.66836 6.00381 1.33418 4.33608 1.33418Z" fill="#8774FF"/>
    </svg>

)

export const BBLock = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C4.79086 0 3 1.79086 3 4V7H2C0.895431 7 0 7.89543 0 9V17C0 18.1046 0.895431 19 2 19H14C15.1046 19 16 18.1046 16 17V9C16 7.89543 15.1046 7 14 7H13V4C13 1.79086 11.2091 0 9 0H7ZM11 5V7H5V5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5ZM10 12C10 12.7403 9.5978 13.3866 9 13.7324V16H7V13.7324C6.4022 13.3866 6 12.7403 6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12Z" fill="#FFFFFF"/>
    </svg>
)

export const BBLockGrey = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C3.92893 0 2.25 1.67893 2.25 3.75V5.52622H4.00008V4C4.00008 2.89543 4.89551 2 6.00008 2C7.10465 2 8.00008 2.89543 8.00008 4V5.52622H9.75V3.75C9.75 1.67893 8.07107 0 6 0ZM0 7.52658C0 6.42201 0.895431 5.52658 2 5.52658H10C11.1046 5.52658 12 6.42201 12 7.52658V13.0001C12 14.1047 11.1046 15.0001 10 15.0001H2C0.895431 15.0001 0 14.1047 0 13.0001V7.52658ZM7.49977 9.47345C7.49977 10.0577 7.19834 10.5678 6.75024 10.8409V12.6311H5.25024V10.8414C4.80163 10.5685 4.49977 10.0581 4.49977 9.47345C4.49977 8.60144 5.17135 7.89453 5.99977 7.89453C6.8282 7.89453 7.49977 8.60144 7.49977 9.47345Z" fill="#6F6F6F"/>
    </svg>
)

export const BBDrugsLocked = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="86" viewBox="0 0 46 86" fill="none">
        <path d="M4.09944 20.8678L20.8611 4.10075C23.5364 1.46119 27.1467 -0.0128942 30.9044 8.49888e-05C34.6621 0.0130642 38.2621 1.51205 40.9192 4.17003C43.5762 6.82801 45.0746 10.4293 45.0875 14.1881C45.1004 17.947 43.6266 21.5584 40.9878 24.2346L24.2268 41.001C21.5515 43.6406 17.9413 45.1148 14.1836 45.1019C10.4259 45.0891 6.82583 43.5902 4.1687 40.9323C1.51157 38.2744 0.01306 34.6732 8.49616e-05 30.9143C-0.01289 27.1554 1.46072 23.544 4.09944 20.8678ZM7.4659 24.2353C6.28346 25.4074 5.43242 26.8719 4.99934 28.4798C4.56627 30.0877 4.5666 31.7816 5.00032 33.3893L19.201 19.2079L29.2526 29.2627L37.6212 20.8676C39.3896 19.0892 40.3807 16.6818 40.3773 14.1735C40.3739 11.6651 39.3763 9.26045 37.6032 7.48677C35.8301 5.71309 33.4262 4.71515 30.9187 4.71177C28.4111 4.70839 26.0045 5.69985 24.2266 7.46875L7.4659 24.2353Z" fill="url(#paint0_linear_16345_39930)"/>
        <path d="M4.98017 33.2517L19.2018 18.8946L29.357 29.1183L19.8487 37.9099C10.6927 45.3655 4.98017 33.2517 4.98017 33.2517Z" fill="url(#paint1_linear_16345_39930)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4276 62.782C16.4276 60.141 18.5615 58 21.194 58H23.5772C26.2096 58 28.3436 60.141 28.3436 62.782V65.9286H28.3449V71.6248H28.4908V71.636H29.6824C30.9986 71.636 32.0656 72.7065 32.0656 74.027V83.5911C32.0656 84.9116 30.9986 85.9821 29.6824 85.9821H15.3832C14.067 85.9821 13 84.9116 13 83.5911V74.027C13 72.7065 14.067 71.636 15.3832 71.636H16.5748V71.6248H18.958V71.636H25.9603L25.9603 65.9286H25.9604V63.9775C25.9604 61.9968 24.3599 60.391 22.3856 60.391C20.4113 60.391 18.8108 61.9968 18.8108 63.9775V66.3573H16.4276V62.782ZM24.916 77.6136C24.916 78.4986 24.4367 79.2713 23.7244 79.6847V82.3956H21.3412V79.6847C20.6289 79.2713 20.1496 78.4986 20.1496 77.6136C20.1496 76.293 21.2166 75.2226 22.5328 75.2226C23.849 75.2226 24.916 76.293 24.916 77.6136Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_16345_39930" x1="22.5438" y1="0" x2="22.5438" y2="45.102" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFEB93"/>
        <stop offset="0.5" stop-color="#FCDE17"/>
        <stop offset="1" stop-color="#ECA511"/>
        </linearGradient>
        <linearGradient id="paint1_linear_16345_39930" x1="11.7554" y1="26.3434" x2="21.9465" y2="36.5312" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFEB93"/>
        <stop offset="0.5" stop-color="#FCDE17"/>
        <stop offset="1" stop-color="#ECA511"/>
        </linearGradient>
        </defs>
    </svg>
)

export const BBRoutinesLocked = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="86" viewBox="0 0 48 86" fill="none">
        <path d="M40.7334 14.3811C42.0752 14.3811 43.1295 15.4117 43.1295 16.778C43.1295 17.4136 42.8771 18.0233 42.4277 18.4728C41.9783 18.9223 41.3689 19.1748 40.7334 19.1748C39.3676 19.1748 38.3373 18.0962 38.3373 16.778C38.3373 15.4117 39.3676 14.3811 40.7334 14.3811ZM0 0V21.5717C0 28.6663 5.24742 34.7543 12.3159 35.737C13.8014 42.9516 20.175 47.937 27.555 47.937C29.6002 47.937 31.6255 47.534 33.5151 46.7511C35.4047 45.9681 37.1216 44.8206 38.5678 43.3739C40.014 41.9272 41.1613 40.2097 41.944 38.3195C42.7266 36.4293 43.1295 34.4034 43.1295 32.3575V23.5131C45.909 22.5064 47.9217 19.8699 47.9217 16.778C47.9217 14.8709 47.1643 13.042 45.8163 11.6935C44.4682 10.345 42.6399 9.5874 40.7334 9.5874C38.827 9.5874 36.9986 10.345 35.6506 11.6935C34.3025 13.042 33.5452 14.8709 33.5452 16.778C33.5452 19.8699 35.5579 22.5304 38.3373 23.5131V32.1418C38.3373 38.1339 33.5452 42.9276 27.555 42.9276C22.7628 42.9276 18.7374 40.0274 17.2997 35.7131C23.9608 34.275 28.753 28.2828 28.753 21.5717V0H23.9608V7.19055V21.5717C23.9608 24.1144 22.9511 26.553 21.1536 28.351C19.3562 30.149 16.9184 31.1591 14.3765 31.1591C11.8346 31.1591 9.39676 30.149 7.59935 28.351C5.80194 26.553 4.79217 24.1144 4.79217 21.5717V7.19055V0H0Z" fill="url(#paint0_linear_16345_39913)"/>
        <path d="M4.79224 0H8.5844C9.13669 0 9.5844 0.447715 9.5844 1V6.19055C9.5844 6.74284 9.13669 7.19055 8.5844 7.19055H4.79224V0Z" fill="url(#paint1_linear_16345_39913)"/>
        <path d="M19.1687 1C19.1687 0.447715 19.6164 0 20.1687 0H23.9609V7.19055H20.1687C19.6164 7.19055 19.1687 6.74284 19.1687 6.19055V1Z" fill="url(#paint2_linear_16345_39913)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.1296 23.5131C45.9091 22.5064 47.9218 19.8698 47.9218 16.7779C47.9218 14.8709 47.1645 13.0419 45.8164 11.6934C44.4683 10.3449 42.64 9.58736 40.7335 9.58736C38.8271 9.58736 36.9987 10.3449 35.6507 11.6934C34.3026 13.0419 33.5453 14.8709 33.5453 16.7779C33.5453 19.8698 35.558 22.5303 38.3375 23.5131C39.0393 23.835 40.9802 24.2857 43.1296 23.5131ZM43.1296 16.7779C43.1296 15.4117 42.0753 14.3811 40.7335 14.3811C39.3677 14.3811 38.3374 15.4117 38.3374 16.7779C38.3374 18.0962 39.3677 19.1748 40.7335 19.1748C41.369 19.1748 41.9784 18.9223 42.4278 18.4728C42.8771 18.0233 43.1296 17.4136 43.1296 16.7779Z" fill="url(#paint3_linear_16345_39913)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4276 62.782C17.4276 60.141 19.5615 58 22.194 58H24.5772C27.2096 58 29.3436 60.141 29.3436 62.782V65.9286H29.3449V71.6248H29.4908V71.636H30.6824C31.9986 71.636 33.0656 72.7065 33.0656 74.027V83.5911C33.0656 84.9116 31.9986 85.9821 30.6824 85.9821H16.3832C15.067 85.9821 14 84.9116 14 83.5911V74.027C14 72.7065 15.067 71.636 16.3832 71.636H17.5748V71.6248H19.958V71.636H26.9603L26.9603 65.9286H26.9604V63.9775C26.9604 61.9968 25.3599 60.391 23.3856 60.391C21.4113 60.391 19.8108 61.9968 19.8108 63.9775V66.3573H17.4276V62.782ZM25.916 77.6136C25.916 78.4986 25.4367 79.2713 24.7244 79.6847V82.3956H22.3412V79.6847C21.6289 79.2713 21.1496 78.4986 21.1496 77.6136C21.1496 76.293 22.2166 75.2226 23.5328 75.2226C24.849 75.2226 25.916 76.293 25.916 77.6136Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_16345_39913" x1="23.9608" y1="0" x2="23.9608" y2="47.937" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFEB93"/>
        <stop offset="0.5" stop-color="#FCDE17"/>
        <stop offset="1" stop-color="#ECA511"/>
        </linearGradient>
        <linearGradient id="paint1_linear_16345_39913" x1="7.18832" y1="0" x2="7.18832" y2="7.19055" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFEB93"/>
        <stop offset="0.5" stop-color="#FCDE17"/>
        <stop offset="1" stop-color="#ECA511"/>
        </linearGradient>
        <linearGradient id="paint2_linear_16345_39913" x1="21.5648" y1="0" x2="21.5648" y2="7.19055" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFEB93"/>
        <stop offset="0.5" stop-color="#FCDE17"/>
        <stop offset="1" stop-color="#ECA511"/>
        </linearGradient>
        <linearGradient id="paint3_linear_16345_39913" x1="40.7335" y1="9.58736" x2="40.7335" y2="23.9397" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFEB93"/>
        <stop offset="0.5" stop-color="#FCDE17"/>
        <stop offset="1" stop-color="#ECA511"/>
        </linearGradient>
        </defs>
    </svg>
)

export const TextIcon = props=> (
    <svg xmlns="http://www.w3.org/2000/svg" width={props?.size || "13"} height={props?.size || "13"} viewBox="0 0 13 13" fill="none">
        <path 
            d="M3.16768 5.06836C2.81781 5.06836 2.53418 5.35199 2.53418 5.70186C2.53418 6.05173 2.81781 6.33536 3.16768 6.33536H9.50268C9.85255 6.33536 10.1362 6.05173 10.1362 5.70186C10.1362 5.35199 9.85255 5.06836 9.50268 5.06836H3.16768ZM3.16768 6.96886C2.81781 6.96886 2.53418 7.25249 2.53418 7.60236C2.53418 7.95223 2.81781 8.23586 3.16768 8.23586H9.50268C9.85255 8.23586 10.1362 7.95223 10.1362 7.60236C10.1362 7.25249 9.85255 6.96886 9.50268 6.96886H3.16768ZM3.16768 8.86936C2.81781 8.86936 2.53418 9.15299 2.53418 9.50286C2.53418 9.85273 2.81781 10.1364 3.16768 10.1364H7.33068C7.68055 10.1364 7.96418 9.85273 7.96418 9.50286C7.96418 9.15299 7.68055 8.86936 7.33068 8.86936H3.16768Z" 
            fill={props?.secondaryColor || "#999999"}
        />
        <path d="M11.403 0H1.267C0.576483 0.02534 0.02534 0.576477 0 1.26699V11.403C0.02534 12.0935 0.576483 12.6447 1.267 12.67H11.403C12.0935 12.6447 12.6447 12.0935 12.67 11.403V1.26699C12.6447 0.576477 12.0935 0.02534 11.403 0ZM11.403 10.403C11.403 10.9553 10.9553 11.403 10.403 11.403H2.267C1.71471 11.403 1.267 10.9553 1.267 10.403V4.801C1.267 4.24871 1.71471 3.801 2.267 3.801H10.403C10.9553 3.801 11.403 4.24872 11.403 4.801V10.403Z" 
            fill={props.primaryColor}
        />
    </svg>
)

export const EnumListIcon = ({primaryColor, secondaryColor = "#6f6f6f", size = "20"}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V6V18C0 19.1046 0.895432 20 2 20L18 20C19.1046 20 20 19.1046 20 18V6V2C20 0.895431 19.1046 0 18 0H2ZM18 6H2V18H18V6Z" fill={primaryColor}/>
        <rect x="8" y="8" width="8" height="2" fill={secondaryColor}/>
        <rect x="8" y="11" width="8" height="2" fill={secondaryColor}/>
        <rect x="8" y="14" width="8" height="2" fill={secondaryColor}/>
        <rect x="4" y="8" width="2" height="2" fill={secondaryColor}/>
        <rect x="4" y="11" width="2" height="2" fill={secondaryColor}/>
        <rect x="4" y="14" width="2" height="2" fill={secondaryColor}/>
    </svg>
)

export const TableIcon = ({primaryColor, secondaryColor = "#6f6f6f", size = "20"}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2C0 0.89543 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V6H0V2Z" fill={primaryColor}/>
        <path d="M20 18C20 19.1046 19.1046 20 18 20L2 20C0.895432 20 7.82809e-08 19.1046 1.74845e-07 18L20 18Z" fill={primaryColor}/>
        <rect y="6" width="2" height="12" fill={primaryColor}/>
        <rect x="18" y="6" width="2" height="12" fill={primaryColor}/>
        <rect x="3" y="7" width="4" height="2" fill={secondaryColor}/>
        <rect x="3" y="10" width="4" height="2" fill={secondaryColor}/>
        <rect x="3" y="13" width="4" height="2" fill={secondaryColor}/>
        <rect x="8" y="7" width="4" height="2" fill={secondaryColor}/>
        <rect x="8" y="10" width="4" height="2" fill={secondaryColor}/>
        <rect x="8" y="13" width="4" height="2" fill={secondaryColor}/>
        <rect x="13" y="7" width="4" height="2" fill={secondaryColor}/>
        <rect x="13" y="10" width="4" height="2" fill={secondaryColor}/>
        <rect x="13" y="13" width="4" height="2" fill={secondaryColor}/>
        <rect x="3" y="16" width="4" height="1" fill={secondaryColor}/>
        <rect x="8" y="16" width="4" height="1" fill={secondaryColor}/>
        <rect x="13" y="16" width="4" height="1" fill={secondaryColor}/>
    </svg>
)

export const FlowchartIcon = ({primaryColor, secondaryColor = "#6f6f6f", size = "20"}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2C0 0.89543 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V6H0V2Z" fill={primaryColor}/>
        <path d="M20 18C20 19.1046 19.1046 20 18 20L2 20C0.895432 20 7.82809e-08 19.1046 1.74845e-07 18L20 18Z" fill={primaryColor}/>
        <rect y="6" width="2" height="12" fill={primaryColor}/>
        <rect x="18" y="6" width="2" height="12" fill={primaryColor}/>
        <rect x="4" y="14" width="3" height="3" fill={secondaryColor}/>
        <rect x="7" y="7" width="5" height="2" fill={secondaryColor}/>
        <rect x="5" y="12" width="1" height="2" fill={secondaryColor}/>
        <rect x="9" y="9" width="1" height="2" fill={secondaryColor}/>
        <rect x="5" y="11" width="9" height="1" fill={secondaryColor}/>
        <rect x="9" y="12" width="1" height="2" fill={secondaryColor}/>
        <rect x="13" y="12" width="1" height="2" fill={secondaryColor}/>
        <rect x="8" y="14" width="3" height="3" fill={secondaryColor}/>
        <rect x="12" y="14" width="3" height="3" fill={secondaryColor}/>
    </svg>
)

export const BBVectorIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.16 9.08L0.160001 7.08L12.16 7.08L6.66 1.58L8.08 0.159999L16 8.08L8.08 16L6.66 14.58L12.16 9.08L0.16 9.08Z" fill="#6F6F6F"/>
    </svg>
)

export const BBSearchChevron = ({isUpDirection = false, color = '#999'}) => (
    <svg 
        width="12"
        height="8"
        viewBox="0 0 12 8" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg" 
        style={{transform: isUpDirection ? 'rotate(180deg)' : ''}}
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41 8L6 3.42L10.59 8L12 6.59L6 0.59L1.23266e-07 6.59L1.41 8Z" fill={color}/>
    </svg>
)

export const BBQRCode = ({ color = 'black' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3H7.5V7.5H3V3ZM15 3V7.5H10.5V3H15ZM10.5 11.25H12V9.75H10.5V8.25H12V9.75H13.5V8.25H15V9.75H13.5V11.25H15V13.5H13.5V15H12V13.5H9.75V15H8.25V12H10.5V11.25ZM12 11.25V13.5H13.5V11.25H12ZM3 15V10.5H7.5V15H3ZM4.5 4.5V6H6V4.5H4.5ZM12 4.5V6H13.5V4.5H12ZM4.5 12V13.5H6V12H4.5ZM3 8.25H4.5V9.75H3V8.25ZM6.75 8.25H9.75V11.25H8.25V9.75H6.75V8.25ZM8.25 4.5H9.75V7.5H8.25V4.5Z"
      fill={color}
    />
    <path
      d="M2 2V4H0V1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H4V2H2ZM16.5 0C16.8978 0 17.2794 0.158035 17.5607 0.43934C17.842 0.720644 18 1.10218 18 1.5V4H16V2H14V0H16.5ZM2 14V16H4V18H1.5C1.10218 18 0.720644 17.842 0.43934 17.5607C0.158035 17.2794 0 16.8978 0 16.5V14H2ZM16 16V14H18V16.5C18 16.8978 17.842 17.2794 17.5607 17.5607C17.2794 17.842 16.8978 18 16.5 18H14V16H16Z"
      fill={color}
    />
  </svg>
);

export const BBBackArrow = ({ color = "#8774FF"}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.84 6.92V8.92H3.84L9.34 14.42L7.92 15.84L0 7.92L7.92 0L9.34 1.42L3.84 6.92H15.84Z"
      fill={color}
    />
  </svg>
);

export const BBLogo1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="74" viewBox="0 0 200 74" fill="none">
        <g clip-path="url(#clip0_3493_20)">
        <path d="M15.1312 61.6082C18.1884 60.7845 20.1012 58.9767 20.1012 56.8372C20.1012 53.8132 17.2043 52.4974 10.5924 52.4974H0V52.6965L0.989579 56.1517V69.5746L0 73.0243V73.2233H8.91727C16.4524 73.2565 20.759 70.8848 20.759 66.6446C20.759 63.7809 18.5864 61.9067 15.1312 61.6082ZM8.15988 54.5042C8.78459 54.1449 9.44246 53.9459 10.1335 53.9459C11.8418 53.9459 12.9309 55.1621 12.9309 57.2353C12.9309 59.8999 11.3498 61.4479 8.15988 61.9399V54.5042ZM10.9572 71.7749C9.87368 71.7749 8.91727 71.4432 8.15988 70.7521V63.615C8.71825 63.4491 9.21027 63.3828 9.67466 63.3828C12.0076 63.3828 13.5887 65.1574 13.5887 67.8884C13.5887 70.2269 12.4997 71.7749 10.9572 71.7749Z" fill="white"/>
        <path d="M38.295 66.8712C36.2219 69.3701 33.4577 71.0507 30.5664 71.6754V56.1462L32.048 52.691V52.4919H22.4065V52.691L23.3961 56.1462V69.5691L22.4065 73.0243V73.2233H36.9461L38.6212 67.0039L38.295 66.8712Z" fill="white"/>
        <path d="M52.9342 52.4974H44.9733V52.6965L46.0901 55.8863L40.429 69.8344L38.4554 73.0243V73.2233H44.2436V73.0243L42.8615 69.9008L43.945 66.7441H50.0981L51.2149 69.9339L50.358 73.0243V73.2233H60.6905V73.0243L58.9822 69.7017L52.9342 52.4974ZM44.4813 65.1629L46.9801 57.86L49.5453 65.1629H44.4813Z" fill="white"/>
        <path d="M73.9918 67.7944C69.9782 67.7944 67.214 64.306 67.214 59.0099C67.214 56.2125 68.2644 54.4711 69.945 54.4711C71.8191 54.4711 73.5661 56.019 74.8818 58.7832H75.0809L79.2271 54.3107C77.154 52.8955 74.6552 52.1713 71.7251 52.1713C65.1796 52.1713 60.6408 56.5774 60.6408 63.1948C60.5412 69.0826 65.1132 73.6545 70.8738 73.5605C76.1368 73.5605 79.0337 70.9954 79.3267 65.865L79.0281 65.7655C77.8395 67.1034 76.1644 67.7944 73.9918 67.7944Z" fill="white"/>
        <path d="M89.4878 56.1517L90.4774 52.6965V52.4974H81.3279V52.6965L82.3175 56.1517V69.5691L81.3279 73.0243V73.2233H90.4774V73.0243L89.4878 69.5691V56.1517Z" fill="white"/>
        <path d="M101.462 69.1434L96.1661 59.6678L103.961 52.691V52.4919H95.7017V52.691L97.1833 55.7482L90.306 62.8189V63.0235L93.4627 69.9008C94.6458 72.5323 96.3264 73.2233 99.4499 73.2233H104.613V73.0243C103.309 72.1066 102.551 71.0839 101.462 69.1434Z" fill="white"/>
        <path d="M121.071 61.6082C124.129 60.7845 126.041 58.9767 126.041 56.8372C126.041 53.8132 123.145 52.4974 116.533 52.4974H105.94V52.6965L106.93 56.1517V69.5746L105.94 73.0298V73.2289H114.858C122.393 73.262 126.699 70.8903 126.699 66.6501C126.699 63.7809 124.527 61.9067 121.071 61.6082ZM114.1 54.5042C114.725 54.1449 115.383 53.9459 116.074 53.9459C117.782 53.9459 118.871 55.1621 118.871 57.2353C118.871 59.8999 117.29 61.4479 114.1 61.9399V54.5042ZM116.897 71.7749C115.814 71.7749 114.858 71.4432 114.1 70.7521V63.615C114.658 63.4492 115.151 63.3828 115.615 63.3828C117.953 63.3828 119.529 65.1574 119.529 67.8884C119.529 70.2269 118.44 71.7749 116.897 71.7749Z" fill="white"/>
        <path d="M139.298 52.1658C132.952 52.0663 127.816 56.6714 127.949 62.7913C127.949 69.0107 131.995 73.5495 139.099 73.5495C145.452 73.649 150.549 69.0439 150.449 62.924C150.455 57.0031 146.308 52.1658 139.298 52.1658ZM140.88 71.6422C140.023 71.6422 139.171 70.9844 138.281 69.7018C137.391 68.386 136.667 66.7054 136.109 64.6986C135.55 62.6918 135.252 60.685 135.252 58.6782C135.252 55.621 136.009 54.0731 137.524 54.0731C138.381 54.0731 139.232 54.7309 140.122 56.0467C141.012 57.3624 141.736 59.0431 142.295 61.0499C142.853 63.0567 143.152 65.0635 143.152 67.0371C143.152 70.0998 142.394 71.6422 140.88 71.6422Z" fill="white"/>
        <path d="M163.48 52.1658C157.133 52.0663 151.997 56.6714 152.13 62.7913C152.13 69.0107 156.177 73.5495 163.281 73.5495C169.633 73.649 174.73 69.0439 174.63 62.924C174.636 57.0031 170.49 52.1658 163.48 52.1658ZM165.061 71.6422C164.204 71.6422 163.352 70.9844 162.462 69.7018C161.572 68.386 160.848 66.7054 160.29 64.6986C159.731 62.6918 159.433 60.685 159.433 58.6782C159.433 55.621 160.19 54.0731 161.705 54.0731C162.562 54.0731 163.413 54.7309 164.303 56.0467C165.193 57.3624 165.918 59.0431 166.476 61.0499C167.034 63.0567 167.333 65.0635 167.333 67.0371C167.333 70.0998 166.575 71.6422 165.061 71.6422Z" fill="white"/>
        <path d="M196.843 69.1434L191.547 59.6678L199.342 52.691V52.4919H191.083V52.691L192.564 55.7482L185.687 62.8189V63.0235L188.844 69.9008C190.027 72.5323 191.707 73.2233 194.831 73.2233H199.994V73.0243C198.684 72.1066 197.927 71.0839 196.843 69.1434Z" fill="white"/>
        <path d="M184.869 56.1517L185.853 52.6965V52.4974H176.709V52.6965L177.693 56.1517V69.5691L176.709 73.0243V73.2233H185.853V73.0243L184.869 69.5691V56.1517Z" fill="white"/>
        <path d="M82.0964 10.4265C84.1916 9.69124 86.2869 9.30425 88.3268 9.30425C90.4829 9.30425 92.5781 9.65807 94.6458 10.3989C96.6802 11.1341 98.3387 12.1127 99.5771 13.351C100.815 12.1127 102.468 11.1341 104.508 10.3989C106.576 9.65807 108.671 9.30425 110.827 9.30425C112.862 9.30425 114.963 9.68571 117.058 10.4265C119.181 11.1673 120.867 12.1403 122.078 13.351L122.608 13.0248V4.14075C121.337 2.86923 119.645 1.87412 117.561 1.11673C115.444 0.364873 113.298 0 111.093 0C109.008 0 106.863 0.392515 104.718 1.1499C102.54 1.90729 100.821 2.9024 99.5826 4.14075C98.3111 2.86923 96.6194 1.87412 94.5352 1.11673C92.4123 0.364873 90.2673 0 88.0615 0C85.9773 0 83.8323 0.392515 81.6872 1.1499C79.5091 1.90729 77.7897 2.9024 76.5514 4.14075V13.0248L77.0821 13.351C78.2928 12.1403 79.9735 11.1673 82.0964 10.4265Z" fill="white"/>
        <path d="M82.0963 24.2475C84.1916 23.5067 86.2869 23.1252 88.3268 23.1252C90.4829 23.1252 92.5781 23.479 94.6458 24.2198C96.6802 24.9551 98.3387 25.9336 99.5771 27.172C100.815 25.9336 102.468 24.9551 104.508 24.2198C106.576 23.479 108.671 23.1252 110.827 23.1252C112.862 23.1252 114.963 23.5067 117.058 24.2475C119.181 24.9883 120.867 25.9613 122.078 27.172L122.608 26.8458V17.9562C121.337 16.6847 119.645 15.6895 117.561 14.9322C115.443 14.1748 113.298 13.8154 111.093 13.8154C109.008 13.8154 106.863 14.2079 104.718 14.9653C102.54 15.7227 100.821 16.7178 99.5826 17.9562C98.3111 16.6847 96.6194 15.6895 94.5352 14.9322C92.4178 14.1748 90.2728 13.8154 88.067 13.8154C85.9828 13.8154 83.8378 14.2079 81.6928 14.9653C79.5146 15.7227 77.7953 16.7178 76.5569 17.9562V26.8403L77.0876 27.1665C78.2928 25.9557 79.9735 24.9827 82.0963 24.2475Z" fill="white"/>
        <path d="M122.072 38.6821L122.603 38.3559V29.4718H76.5514V38.3559L77.0821 38.6821H122.072Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_3493_20">
        <rect width="200" height="73.555" fill="white"/>
        </clipPath>
        </defs>
    </svg>
)