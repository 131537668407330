import { BBArrowSeeAll, BBDrugsLocked, BBRoutinesLocked } from "../../../blackbook-components/quarks/icons";
import { useSidebar } from "../../../context/useSidebar";
import { Specialties } from "./style";


export function HomeSpecialtiesCarousel({title, specialties, parentKey, scrollFunction}) {
  const {isSidebarOpen, handleClickItemOnHome} = useSidebar();
  return (
    <Specialties isSidebarOpen={isSidebarOpen}>
      <h2 className="specialties__title">{title}</h2>
      <div className="specialties__carousel" onScroll={()=>scrollFunction(title)}>
        {specialties
          ?.slice("")
          .sort((a, b) => a.position - b.position)
          .map((specialty, index) => {
            if(specialty.title.toLowerCase().includes('free')){
              return (
                <div className="specialtyContainer">
                  <button className="specialty free" onClick={() => handleClickItemOnHome({item_key: specialty.specialty_id, base: parentKey })}>
                    {
                      specialty.title.toLowerCase().includes('rotinas') ?
                      <BBRoutinesLocked/>
                      :
                      <BBDrugsLocked/>
                    }
                  </button>
                  <p className="specialty__title">{specialty.title}</p>
                </div>
              );
            }
            return (
              index <= 6 && (
                <div className="specialtyContainer">
                  <button className="specialty" onClick={() => handleClickItemOnHome({item_key: specialty.specialty_id, base: parentKey })}>
                    <img
                      // src={`data:image/png;base64, ${specialty.cover}`}
                      src={`${process.env.REACT_APP_FIREBASE_URL}homeSpecialties%2F${specialty.cover}.png?alt=media`} 
                      className="specialty__cover"
                      alt=""
                    />
                  </button>
                  <p className="specialty__title">{specialty.title}</p>
                </div>
              )
            );
          })}
        <div className="specialtyContainer">
          <button className="specialty seeAll" onClick={() => handleClickItemOnHome({base: parentKey })}>
            VER <br/> TODAS <BBArrowSeeAll />
          </button>
          <p className="specialty__title seeAll">ver todas</p>
        </div>
      </div>
    </Specialties>
  );
}