import PropTypes from 'prop-types';
import { ContentBannerStyle } from "./style";

export const ContentBannerComponent = (props) => {
    return (
        <ContentBannerStyle>
            <div className="image">
                <img src={props.image} alt={props.title} />
                <div className="cover"></div>
            </div>
            <div className="title">
                <h2>
                    {props.title} <br />
                    {props.legend}
                </h2>
            </div>
        </ContentBannerStyle>
    )
}

ContentBannerComponent.propTypes = {
    title: PropTypes.string,
    legend: PropTypes.string,
    image: PropTypes.string,
}