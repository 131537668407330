import PropTypes from "prop-types";
import React, { useRef } from "react";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { BBArrowDownIcon } from "../../quarks/icons";
import { routineColorSelector } from "../../quarks/theme";
import { ContentSidebarStyle } from "./style";

const Item = (item) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const hasSubItems = (item) => {
    return (
      (item?.cards || item?.subItems) &&
      (item?.cards?.length > 0 || item?.subItems?.length > 0)
    );
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    item.onClick?.({ ...item });
  };

  const isItemActive = (item) => {
    return item._id === item.hightlightedItem?.card;
  };

  const getClasses = (item, index) => {
    let classes = [];
    if (item.className) {
      classes.push(item.className);
    }
    if (isItemActive(item)) {
      classes.push("active");
      // scrollHighlightedItem(index);
    }
    return classes.join(" ");
  };

  const listItemsRef = useRef([]);

  function scrollHighlightedItem(index) {
    listItemsRef?.current[index]?.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  if(item.hightlightedItem?.tab === item._id && !isOpen) {
    setIsOpen(true);
  }

  return (
    <li
      id={`sidebar-${item._id}`}
      key={item._id}
      className={getClasses(item, item.index)}
      style={{ borderColor: !item.cards && routineColorSelector(item.class_css).base }}
      ref={(ref) => (listItemsRef.current[item._id] = ref)}
    >
      <button onClick={() => handleClick()} className={getClasses(item)}>
        <span style={{ color: routineColorSelector(item.class_css).base }}>
          {item.text || item.title}
        </span>{" "}
        {hasSubItems(item) && 
        <div style={{width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <BBArrowDownIcon isUpDirection={isOpen}/>
        </div>}
      </button>
      {hasSubItems(item) && isOpen && (
        <ItemList
          items={item.subItems || item.cards.filter((card)=>card.is_show_in_navigation)}
          className={`sub_item_menu`}
          isActive={isItemActive(item)}
          onClick={(e) => item.onChildClick(e, item)}
          hightlightedItem={item.hightlightedItem}
        />
      )}
    </li>
  );
};

const ItemList = (props) => {
  return (
    <ul>
      {props.items?.map((item, index) => (
        <Item {...item} index={index} {...props} />
      ))}
    </ul>
  );
};

export const ContentRoutineSidebar = (props) => {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <ContentSidebarStyle {...props} active={props.isActive} isOpen={isOpen}>
      <ItemList {...props} />
      <button onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaCaretLeft /> : <FaCaretRight />}
      </button>
    </ContentSidebarStyle>
  );
};

ContentRoutineSidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.element,
      isActive: PropTypes.bool,
      className: PropTypes.string,
      color: PropTypes.string,
      sub_items: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          isActive: PropTypes.bool,
          className: PropTypes.string,
          sub_items: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
              isActive: PropTypes.bool,
              className: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
  onClick: PropTypes.func,
};
