export const MaleIcon = () => (
  <svg
    fill="#a4cef4"
    height="40px"
    width="40px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 345.6 345.6"
    stroke="#a4cef4"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path d="M336.843,8.927c-5.959-5.976-14.148-9.174-22.594-8.787L211.774,4.758c-16.551,0.746-29.365,14.769-28.619,31.32 c0.746,16.552,14.789,29.342,31.32,28.619l24.955-1.125l-32.602,32.602c-21.324-13.469-46.053-20.681-71.893-20.681 c-36.061,0-69.963,14.042-95.459,39.541c-52.637,52.635-52.637,138.281,0,190.917c25.496,25.499,59.398,39.54,95.459,39.54 c36.061,0,69.961-14.041,95.459-39.54c45.348-45.348,51.623-115.193,18.834-167.326l32.701-32.701l-1.195,24.942 c-0.793,16.55,11.979,30.608,28.529,31.402c0.49,0.023,0.975,0.035,1.461,0.035c15.906,0,29.17-12.505,29.941-28.564l4.898-102.193 C345.97,23.115,342.804,14.904,336.843,8.927z M187.97,263.526c-14.166,14.165-33,21.966-53.033,21.966 c-20.035,0-38.869-7.801-53.033-21.966c-29.242-29.243-29.242-76.823,0-106.066c14.164-14.165,33-21.966,53.033-21.966 s38.869,7.801,53.033,21.966C217.212,186.701,217.212,234.282,187.97,263.526z"></path>{" "}
    </g>
  </svg>
);
