import styled from "styled-components";
import { colors, fontSize, spacing } from "../../blackbook-components/quarks/theme";

export const ContentWrapper = styled.div`
  height: calc(100% - 40px);
  padding: 20px;

  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 20px;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${colors.black100};

  .bannerSection {
    min-height: 220px;
    width: 100%;
    max-width: ${(props) => 
      props.isSidebarOpen ? "calc(100vw - 280px)" : "calc(100vw - 40px)"
    };
    max-height: 350px;
    position: relative;
    border-radius: 10px;
    .swiper {
      height: 100%;
      overflow: visible;
    }
    .swiper-slide {
      z-index: -10;
      width: 100%;
      background-color: #7515ed;
      border-radius: 10px;
    }
    .swiper-button-disabled {
      display: none;
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      margin-bottom: -16px;
      text-align: center;
      transition: 0.3s opacity;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      z-index: 10;
      .swiper-pagination-bullet {
        background: ${colors.disableGray};
        opacity: unset;
      }
      .swiper-pagination-bullet-active {
        background: ${colors.secondary};
      }
    }
  }
`;