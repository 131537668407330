import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 0.7rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  margin-top: 1.2rem;
  background-color: #8774FF;
  color: white;

  &:active {
    background-color: var(--purple-500);
    color: white;
    transition: linear 0.09s;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  &.overviewContent__button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid var(--purple-500);
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;

    &:hover {
      background-color: var(--purple-500);
      cursor: pointer;
      transition: 0.2s linear;
    }

    &:active {
      background-color: var(--purple-500);
      scale: 1.1;
    }
    @media screen and (min-width: 768px) {
      align-self: flex-start;
      width: 25%;
      display: inline-block;
    }
  }

  &.paymentBox__button {
    border-radius: 20px;
    background-color: var(--purple-500);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: filter 0.2s;
    padding: 1rem;

    &:hover {
      filter: brightness(0.9);
    }
  }

  &.outlined__button {
    border-radius: 20px;
    background-color: transparent;
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: filter 0.2s;
    padding: 1rem;
    border: 1px solid var(--yellow-500);
    outline: none;


    &:hover {
      filter: brightness(0.9);
    }
  }

  &.subscriptionBox__button {
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid var(--yellow-500);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.2s, background-color 0.2s;
    padding: 0.5rem;

    &:hover {
      background-color: var(--yellow-500);
      color: var(--black);
    }
  }

  &.cancelSubscriptionBox__button {
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid var(--red-500);
    color: var(--white);
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.2s, background-color 0.2s;
    padding: 0.5rem;

    &:hover {
      background-color: var(--red-500);
      color: var(--black);
    }
  }

  &.validateCoupon__button {
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid var(--yellow-500);
    color: var(--white);
    cursor: pointer;
    font-weight: bold;
    width: auto;
    align-self: flex-end;
    transition: color 0.2s,
    background-color 0.2s;
    margin: 0 0 0.5rem 0;

    &:hover {
      background-color: var(--yellow-500);
      color: var(--black);
    }
  }

  &.buttonContainer__button {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 25px;
    margin: 0;
    color: white;
    transition: linear 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;

const buttonStyle = `
  display: inline-block;
  padding: 17.5px 30px;
  border: none;
  font-weight: bold;
  font-size: 0.7rem;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
  background-color: var(--purple-500);
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
      filter: brightness(0.9);
  }

  &.fullWidth {
    width: 100%;
  }

  &[disabled] {
    background-color: #6F6F6F;
    cursor: not-allowed;
  }
`;


export const NewButton = styled.button`
  ${buttonStyle}
`;

export const LinkButton = styled.a`
  ${buttonStyle}
`;


