import PropTypes from 'prop-types';
import { usePaywall } from '../../../services/hooks/handleRedirectToPaywall';
import { BBLock } from '../../quarks/icons';
import { colors } from '../../quarks/theme';
import { RoutineCardStyle } from './style';
import { useTrack } from '../../../services/hooks/useTrack';

export const RoutineCardComponent = (props) => {
  const {redirectToPaywall} = usePaywall()
  const {trackEvent} = useTrack()

  return (
  
  <RoutineCardStyle
    isResumeCard={props.isResumeCard}
    color={props.color || colors.softBlack}
    {...props}
  >
    <h3 className="card__title">
      {props.cardTitle}
      {props.isPaid && 
        <button 
          className="paid__button" 
          type="button" 
          onClick={() => {
            trackEvent(
              "Clicou no cadeado",
              {
                'Origem': "Rotinas",
                'Rotina': props?.routine_title,
                'Tab': props?.tab_title,
                'Card': props?.card_title
              }
            );
            redirectToPaywall()
          }}
        >
          <BBLock/>
        </button>
      }
    </h3>
    {
      !props.isPaid &&
      <div className="card__body">
        {props.children}
      </div>
    }
  </RoutineCardStyle>
)};

RoutineCardComponent.propTypes = {
  cardTitle: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
};

RoutineCardComponent.defaultProps = {
  color: colors.softBlack,
};
