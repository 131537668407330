import { borderRadius, fontFamily, fontSize, spacing } from '../../quarks/theme';
import styled from 'styled-components';

export const ContentBannerStyle = styled.div`
display: flex;
border-radius: ${borderRadius.small};
overflow: hidden;
padding: 20px 30px 0;
.image {
    position: relative;
    width: 320px;
    height: 239px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .cover {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 99.99%, rgba(255, 255, 255, 0) 100%);
    }
}
.title {
    display: flex;
    align-items: end;
    height: 240px;
    h2 {
        padding: ${spacing.large};
        font-size: 25px;
        font-weight: 300;
        font-family: ${fontFamily.secondary};
        margin-bottom: ${spacing.large};
        line-height: 1.3;
    }
}
`
