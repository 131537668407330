import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../quarks/sizes';
import { colors, spacing } from '../../quarks/theme';

export const HeaderStyle = styled.div`
    color: ${colors.white};
    .navbar_area {
        display: grid;
        grid-template-columns: ${SIDEBAR_WIDTH}px 1fr 150px;
        width: 100%;
    }
    .logo_area {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: ${spacing.xlarge};
        padding-right: ${spacing.xlarge};
        svg {
            cursor: pointer;
            padding: ${spacing.medium};
            color: ${colors.primary};
        }
        @media screen and (max-width: 420px) {
            width: 32px;
            .icon {
                display: none;
            }
        }
    }
    .search_area {
        position: relative;
        padding: ${spacing.none} ${spacing.mlarge};
        &::before, &::after {
            content: '';
            height: 16px;
            width: 2px;
            background: ${colors.black350};
            z-index: 99;
            position: absolute;
            top: 7px;
        }

        &::after {
            right: 0;
        }

        &::before {
            left: 0;
        }

        @media screen and (max-width: 420px) {
            display: none;
        }
    }
    .user_info {
        padding: ${spacing.none} ${spacing.xlarge};

        @media screen and (max-width: 420px) {
            width: 300px;
            padding-right: ${spacing.none};
        }
    }
`
