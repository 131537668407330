export const Calculator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
  >
    <rect x="2" y="6" width="10" height="2" fill="#6F6F6F" />
    <rect x="4" y="8" width="2" height="10" fill="#6F6F6F" />
    <rect x="8" y="8" width="2" height="10" fill="#6F6F6F" />
    <rect x="2" y="10" width="10" height="2" fill="#6F6F6F" />
    <rect x="2" y="14" width="10" height="2" fill="#6F6F6F" />
    <path d="M2 2H11V5H2V2Z" fill="#8774FF" />
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2H0Z"
      fill="#6F6F6F"
    />
    <path
      d="M14 18C14 19.1046 13.1046 20 12 20L2 20C0.89543 20 5.47966e-08 19.1046 1.22392e-07 18V18L14 18V18Z"
      fill="#6F6F6F"
    />
    <rect y="2" width="2" height="16" fill="#6F6F6F" />
    <rect x="12" y="2" width="2" height="16" fill="#6F6F6F" />
  </svg>
);
