import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ContentRoutineSidebar } from '../../blackbook-components/molecules/content-routineSidebar';
import { PopupContentComponent } from '../../blackbook-components/molecules/popup-content';
import { RoutineDetailComponent } from '../../blackbook-components/organisms/routine-detail';
import { BBLoadingGif } from '../../blackbook-components/quarks/blackbook-loading';
import { AccordionTable } from '../../components/atoms/AccordionTable';
import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
import { usePopup } from '../../context/usePopup';
import { useTrack } from '../../services/hooks/useTrack';
import { GET_ROUTINE } from './query';
import { ContentWrapper } from './style';

export function WebAppRoutines() {
  const { routine_id } = useParams();
  const { trackEvent } = useTrack();
  const routineContentRef = useRef();
  const location = useLocation();
  const [hightlightedItem, setHightlightedItem] = useState({
    tab: null,
    card: null,
  });

  function handleScrollSidebarItems(id) {
    const element = document?.getElementById(`sidebar-${id}`);
    element?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }

  function handleKeyboardArrowUp() {
    const activeCard = document?.querySelector('li.sub_item_menu.active');

    if (!activeCard) return;

    const sibling = activeCard?.previousElementSibling;
    if (sibling) {
      const parent = sibling.closest('li.contentSidebar');

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        sibling?.id?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    } else {
      const parent =
        activeCard.closest('li.contentSidebar').previousElementSibling;
      const lastCard = Array.from(
        parent.querySelectorAll('li.sub_item_menu')
      )?.at(-1)?.id;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        lastCard?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    }
  }
  function handleKeyboardArrowDown() {
    const activeCard = document?.querySelector('li.sub_item_menu.active');

    if (!activeCard) {
      const fistCard = document?.querySelector('.routine__card');
      const parent = fistCard?.parentElement;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        fistCard?.id?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );

      return;
    }

    const card = document?.getElementById(
      activeCard?.id.replace('sidebar-', '')
    );

    const sibling = card?.nextElementSibling;
    if (sibling) {
      const parent = sibling.parentElement;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        sibling?.id?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    } else {
      const parent = card.parentElement.nextElementSibling;

      const fistCard = parent.querySelector('.routine__card ')?.id;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        fistCard?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    }
  }

  function handleKeyboardKey(key) {
    if(key === 'End') {
      const lastCard = 
       Array.from(
        document.querySelectorAll('.routine__card:last-of-type ')
      )?.at(-1)
      const parent = lastCard.parentElement
      
      scrollRoutineElementIntoView(
        parent?.id,
        lastCard?.id,
        routineContentRef.current,
      );
    } else if (key === 'Home') {
      const fistCard = document.querySelector('.routine__card ');
      const parent = fistCard.parentElement
      
      scrollRoutineElementIntoView(
        parent?.id,
        fistCard?.id,
        routineContentRef.current,
      );
    }
  }

  useEffect(() => {
    handleScrollSidebarItems(hightlightedItem?.card || hightlightedItem?.tab);
  }, [hightlightedItem]);

  useEffect(() => {
    if (routineContentRef && routineContentRef.current) {

      document?.addEventListener('keydown', (e) => {
        if(e.key === 'End' || e.key === 'Home') {
          e.preventDefault()
          handleKeyboardKey(e.key)
        }

        if (e.key === 'ArrowUp' || e.key === 'PageUp') {
          e.preventDefault();
          if(e.repeat) {
            
            e.preventDefault();
            return false
            
          }
          
          e.stopImmediatePropagation()
          handleKeyboardArrowUp();
          return false
        }
        if (e.key === 'ArrowDown' || e.key === 'PageDown') {
          e.preventDefault();

          if(e.repeat) {
            
            e.preventDefault();
            return false

          }

          handleKeyboardArrowDown();

          return false
        }
      })
    }

    return document?.removeEventListener('keydown', (e) => {
      if (e.key === 'ArrowUp' || e.key === 'PageUp') {
        e.preventDefault();

        handleKeyboardArrowUp();
      }
      if (e.key === 'ArrowDown' || e.key === 'PageDown') {
        e.preventDefault();

        handleKeyboardArrowDown();
      }
    });
  });

  function scrollRoutineElementIntoView(tab, card, elementRef, behavior = 'smooth') {
    const tabElement = document?.getElementById(tab) || document?.getElementById(card)?.parentElement;

    const tabT = tabElement.offsetTop;

    const cardT = !!card ? document?.getElementById(card)?.offsetTop : 0;
    const breadCrumb = tabElement
      ?.querySelector('.breadCrumb__container');
    const breadCrumbHeight = !!breadCrumb ? breadCrumb.offsetHeight : 0;

    if (tabT) {
      elementRef?.scrollTo({
        top: tabT + cardT - breadCrumbHeight - 50,
        behavior: behavior,
      });
    }
  }

  const { loading, error, data } = useQuery(GET_ROUTINE, {
    variables: { routineId: routine_id },
    fetchPolicy: 'no-cache',
    onCompleted: (routine) => {
      if (location.state.trackOrigin === 'Banner') {
        trackEvent('Clicou no banner', {
          Destino: `${routine.routine_populated.title} - ${routine.routine_populated.legend}`,
          Origem: 'Banner',
          'Tipo da origem': 'Banner',
          'Tipo do destino': 'Rotinas',
          'Link id': location.state.trackLink,
        });
      }
      trackEvent('Acessou Rotinas', {
        'Tipo da origem': location?.state?.trackType,
        Origem: location?.state?.trackOrigin,
        Rotina: `${routine?.routine_populated.title} - ${routine?.routine_populated.legend}`,
        Resolução: `(${window.screen?.width * window.devicePixelRatio} X ${window.screen?.height * window.devicePixelRatio})`
      });
    },
  });

  const routine = data?.routine_populated
    ? {
        title: data.routine_populated.title,
        legend: data.routine_populated.legend,
        image: data.routine_populated.image,
        tabs: data.routine_populated.tabs,
        cards: data.routine_populated.tabs.reduce(
          (total, tab) => total.concat(tab.cards),
          []
        ),
        isBlocked: data.routine_populated.is_blocked,
      }
    : null;

  const { popup, setPopup } = usePopup();
  return (
    <WebAppTemplate>
      {popup !== null && (
        <PopupContentComponent isOpen={popup != null} popupTable={true}>
          <AccordionTable
            extra={popup}
            popup={true}
            onClose={() => setPopup(null)}
          />
        </PopupContentComponent>
      )}
      <ContentWrapper>
        {loading || error ? (
          <BBLoadingGif />
        ) : (
          <>
            <ContentRoutineSidebar
              items={routine?.tabs}
              className="contentSidebar light"
              onChildClick={(card, tab) => {
                scrollRoutineElementIntoView(
                  tab._id,
                  card._id,
                  routineContentRef.current
                );
              }}
              onClick={(e) => {
                // console.log("Clicked", e);
              }}
              hightlightedItem={hightlightedItem}
            />
            <RoutineDetailComponent
              routineContentRef={routineContentRef}
              scrollElementIntoView={scrollRoutineElementIntoView}
              setHightlightedItem={setHightlightedItem}
              {...routine}
            />
          </>
        )}
      </ContentWrapper>
    </WebAppTemplate>
  );
}
