import { Button, LinkButton, NewButton } from "./style";
import { Spinner } from "../../../assets/animations/spinner";

export function ButtonComponent({ buttonProp, children }) {
  return <Button {...buttonProp}>{children}</Button>;
}

export function NewButtonComponent(props) {
  return <NewButton {...props}>{
    props.loading ?
      <Spinner
        height="0.7rem"
        stroke_width="5px"
      /> :
      props.children}</NewButton>;
}

export function LinkButtonComponent(props) {
  return <LinkButton {...props}>{props.children}</LinkButton>;
}
