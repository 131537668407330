import React, { useEffect, useState } from 'react';
import { RegisterOrganism } from '../../../components/organisms/registerOrganism';
import { SignUpPhone } from '../Steps/SignUpPhone';

import Logo from '../../../assets/images/logo.png';
import {
  PasswordRegisterInputComponent,
  RegisterInputComponent,
  RegisterSelectComponent,
} from '../../../components/atoms/registerInput';
import { authApi } from '../../../services/clients';
import _ from 'lodash';
import { UseTermsTextBox } from '../Steps/UseTerms/UseTermsTextBox';
import { DesktopSignupContainer } from './style';
import { Modal } from '../../../components/atoms/Modal';
import { useHistory } from 'react-router-dom';
import {
  getAnonymousToken,
  handleSetLoginToken,
} from '../../../services/tokenService';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useTrack } from '../../../services/hooks/useTrack';
import { getGiftCardOfLocalStorage } from '../../../services/cache/giftCard';
import { getIndicationCoupon } from '../../../services/cache/indicationCoupon';

const SET_USER_REGISTER = gql`
  mutation Mutation(
    $email: String
    $password: String
    $name: String
    $phoneNumber: String
    $countryDial: String
    $graduationYear: String
    $actuationAreaId: String
    $anonymousToken: String
    $register_coupon_code: String
  ) {
    register(
      email: $email
      password: $password
      name: $name
      phone_number: $phoneNumber
      country_dial: $countryDial
      graduation_year: $graduationYear
      actuation_area_id: $actuationAreaId
      anonymousToken: $anonymousToken
      register_coupon_code: $register_coupon_code
    ) {
      token
      refreshToken
      _id
    }
  }
`;

const currentYear = new Date().getFullYear();

function generateArrayOfYears() {
  const max = currentYear + 9;
  const min = 1970;
  const years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function DesktopSignup() {
  const history = useHistory();

  const [handleRegister] = useMutation(SET_USER_REGISTER);

  const { trackEvent } = useTrack();

  const [signupStage, setSignupStage] = useState(0);

  const [specialties, setSpecialties] = useState([]);

  const [isUseTermsModalOpen, setIsUseTermsModalOpen] = useState(false);

  async function handleGetSpecialties() {
    try {
      const responseSpecialties = await authApi.getActuationAreas();
      setSpecialties(responseSpecialties.data);
    } catch (error) {}
  }

  useEffect(() => {
    handleGetSpecialties();
  }, []);

  const [user, setUser] = useState({
    name: '',
    phone: '',
    country_dial: '',
    graduation_year: '',
    actuation_area: '',
  });

  const [errors, setErrors] = useState({
    name: null,
    phone: null,
    country_dial: null,
    graduation_year: null,
    actuation_area: null,
  });

  function handleChangeUser(field, value) {
    setUser({ ...user, [field]: value });
    setErrors({ ...errors, [field]: null });
  }

  async function handleValidateFields(fields) {
    let res = {};

    const fieldOtions = {
      name: () => {
        var regexp =
          /[a-zA-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ-áàâãéèêíïóôõöúçñ]+\s+[a-zA-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ-áàâãéèêíïóôõöúçñ]+/g;
        if (!regexp.test(user.name)) {
          res = { ...res, name: 'Nome inválido!' };
        }
      },
      phone: () => {
        if (user.phone === '') {
          res = { ...res, phone: 'Telefone inválido!' };
        }
      },
      country_dial: () => null,
      graduation_year: () => {
        if (user.graduation_year === '') {
          res = { ...res, graduation_year: 'Ano inválido!' };
        }
      },
      actuation_area: () => {
        if (user.actuation_area === '') {
          res = { ...res, actuation_area: 'Área de atuação inválida!' };
        }
      },
      email: async () => {
        try {
          await authApi.getValidEmail(user.email);
          return null;
        } catch (error) {
          switch (error.response.data.code) {
            case 403:
              res = { ...res, email: 'E-mail já cadastrado em nosso sistema.' };
              break;
            default:
              res = { ...res, email: 'E-mail inválido.' };
              break;
          }
        }
      },
      confirm_email: () => {
        if (user.email !== user.confirm_email) {
          res = { ...res, confirm_email: 'Emails devem ser iguais.' };
        }
      },
      password: () => {
        if (user?.password?.length < 6) {
          res = { ...res, password: 'Senha menor que 6 caracteres.' };
        }
      },
      confirm_password: () => {
        if (user.password !== user.confirm_password) {
          res = { ...res, confirm_password: 'Senhas devem ser iguais.' };
        }
      },
    };

    const temp = await Promise.all(fields?.map((e) => fieldOtions[e]()));

    return res;
  }

  const [loading, setLoading] = useState(false);
  async function handleRegisterUser() {
    setLoading(true);

    try {
      const couponStored = getIndicationCoupon()
      const userData = {
        email: user.email,
        password: user.password,
        name: user.name,
        phoneNumber: user.phone,
        countryDial: '+' + user.country_dial,
        graduationYear: user.graduation_year,
        actuationAreaId: user.actuation_area,
        anonymousToken: getAnonymousToken(),
        register_coupon_code: couponStored || ''
      };

      const response = await handleRegister({ variables: userData });
      await handleSetLoginToken(response?.data?.register?.token);

      toast.success('Cadastro completado com sucesso!');
      history.push('/app');

      if (getGiftCardOfLocalStorage() || getIndicationCoupon()) {
        history.push('/home');
      } else {
        history.push('/app');
      }
    } catch {
      toast.error('Ocorreu um erro! Tente novamente mais tarde.');
    }

    setLoading(false);
  }

  async function handleFormByStage() {
    const options = {
      0: async () => {
        const validate = await handleValidateFields([
          'name',
          'phone',
          'country_dial',
          'graduation_year',
          'actuation_area',
        ]);

        if (!_.isEmpty(validate)) {
          setErrors(validate);
          return;
        }
        setSignupStage(1);

        trackEvent('Cadastro perfil - Próximo', {
          name: user.name,
          phone: user.phone,
          country_dial: user.country_dial,
          graduation_year: user.graduation_year,
          actuation_area: user.actuation_area,
        });
      },
      1: async () => {
        const validate = await handleValidateFields([
          'email',
          'confirm_email',
          'password',
          'confirm_password',
        ]);

        if (!_.isEmpty(validate)) {
          setErrors(validate);
          return;
        }

        trackEvent('Cadastro acesso - Próximo', {
          email: user.email,
          confirm_email: user.confirm_email,
          password: user.password,
          confirm_password: user.confirm_password,
        });
        handleRegisterUser();
      },
    };

    options[signupStage]();
  }

  const stage = {
    0: (
      <div className="steps__container">
        <RegisterInputComponent
          autoFocus
          error={errors?.name}
          helper="Digite nome e sobrenome"
          label="Nome completo"
          type="text"
          value={user.name}
          onChange={(e) => handleChangeUser('name', e.target.value)}
        />

        <SignUpPhone
          isDesktop={true}
          desktopCallback={(country_dial, phone) => {
            setUser({ ...user, phone: phone, country_dial: country_dial });
          }}
          desktopErrorMessage={errors?.phone}
          phoneValue={user?.phone}
        />
        <RegisterSelectComponent
          error={errors?.graduation_year}
          description="Se for estudante, informe o ano previsto"
          label="Ano de formatura"
          type="select"
          value={user.graduation_year}
          options={generateArrayOfYears()}
          onChange={(e) => handleChangeUser('graduation_year', e.target.value)}
        />

        <RegisterSelectComponent
          error={errors?.actuation_area}
          className="space-between"
          label="Área de atuação"
          type="select"
          field_value={'_id'}
          field_title={'title'}
          value={user.actuation_area}
          options={specialties}
          onChange={(e) => handleChangeUser('actuation_area', e.target.value)}
        />
      </div>
    ),
    1: (
      <div className="steps__container">
        <div>
          <RegisterInputComponent
            error={errors?.email}
            helper="Digite um email válido"
            label="Email"
            type="email"
            value={user?.email}
            onChange={(e) => handleChangeUser('email', e.target.value)}
          />
          <RegisterInputComponent
            error={errors?.confirm_email}
            label="Confirmar email"
            type="email"
            autofill="off"
            autoComplete="off"
            value={user?.confirm_email}
            onChange={(e) => handleChangeUser('confirm_email', e.target.value)}
          />
        </div>
        <div>
          <PasswordRegisterInputComponent
            label="Senha"
            value={user?.password}
            onChange={(e) => handleChangeUser('password', e.target.value)}
            error={errors?.password}
            min={6}
          />
          <PasswordRegisterInputComponent
            label="Confirmar senha"
            value={user?.confirm_password}
            onChange={(e) =>
              handleChangeUser('confirm_password', e.target.value)
            }
            error={errors?.confirm_password}
            min={6}
          />
        </div>
      </div>
    ),
  };

  function onClickHeaderBackButton() {
    const options = {
      0: () => {
        trackEvent('Cadastro perfil - voltar');
        history.push('signin');
      },
      1: () => {
        trackEvent('Cadastro acessar - voltar');
        setSignupStage(0);
      },
    };

    return options[signupStage]();
  }

  return (
    <DesktopSignupContainer>
      <RegisterOrganism
        title={<img src={Logo} style={{ height: '40px' }} />}
        buttonProps={{
          className: 'fullWidth',
          type: 'button',
          text: signupStage === 0 ? 'PRÓXIMO' : 'CADASTRAR',
          loading: loading,
        }}
        showSigninButton={true}
        onFinish={handleFormByStage}
        onClickHeaderBackButton={() => onClickHeaderBackButton()}
        stepsCount={<span>Etapa {signupStage + 1}/2</span>}
      >
        <h3 className="desktop-header__title">
          {signupStage === 0
            ? 'Vamos lá! Preencha os campos a seguir:'
            : 'Agora, crie seus dados de acesso:'}
        </h3>
        {stage[signupStage]}
      </RegisterOrganism>
      <h5 className="use-terms__container">
        Ao prosseguir, você concorda com as{' '}
        <button type="button" onClick={() => setIsUseTermsModalOpen(true)}>
          Políticas e Diretrizes da Blackbook.
        </button>
        {isUseTermsModalOpen && (
          <Modal onClose={() => setIsUseTermsModalOpen(false)}>
            <UseTermsTextBox />
          </Modal>
        )}
      </h5>
    </DesktopSignupContainer>
  );
}
