import styled from "styled-components";
import { borderRadius, colors, fontSize, spacing } from "../../quarks/theme";

export const ContentSidebarStyle = styled.div`
  position: relative;
  display: inline-block;
  min-height: 100%;
  background: ${colors.black150};
  min-width: ${(props) => (props.isOpen ? "240px" : "0px")};
  max-width: ${(props) => (props.isOpen ? "240px" : "0px")};
  transition: all 0.3s ease;
  box-shadow: 0px -5px 10px 5px rgba(0, 0, 0, 0.75);

  .contentSidebar {
    border-color: ${colors.disableGray};
  }

  button {
    padding: 0;
  }

  ul {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: minmax(max-content, 100%);
    background: ${colors.black150};
    color: ${colors.gray};
    padding: ${spacing.none} ${spacing.small} ${spacing.none} ${spacing.medium};
    margin: 0;
    list-style: none;
    height: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 15px;
    }
    ::-webkit-scrollbar-track {
      background-color: #d8d7d7;
      border-right: 2px solid ${colors.light};
      width: 1px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #b1b1b1;
      background-clip: content-box;
      border-radius: 10px;
      border: 2px solid transparent;
    }

    li {
      ul {
        overflow-y: hidden;
        padding: 0;
        padding-left: ${spacing.medium};
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        cursor: pointer;
        width: 100%;
        min-height: 40px;
        background-color: transparent;
        border: none;
        color: ${colors.gray};
        font-size: ${fontSize.small};
        span {
          display: flex;
          align-items: center;
          max-width: 220px;
          svg {
            min-width: 20px;
            max-width: 20px;
            margin-right: ${spacing.small};
          }
        }
        &.active {
          color: ${colors.white};
          font-weight: bold;
        }
      }
      border-color: ${colors.black};
      border-bottom: 0.5px solid ${colors.black};
      &:first-child {
        border-top: 0;
      }
    }
  }
  li.sub_item_menu {
    &:first-child {
      border-top: 1px solid;
    }
  }
 
  & > button {
    z-index: 55;
    cursor: pointer;
    position: absolute;
    top: 40px;
    left: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0 ${borderRadius.small} ${borderRadius.small} 0;
    background-color: ${colors.black150};
    color: ${colors.white};
  }

  .sub_item_menu {
    width: 100%;
    background-color: ${colors.light};
    &:last-child {
      border-bottom: none;
    }
  }
  &.light {
    background-color: ${colors.light};
    color: ${colors.darkGray};
    ul {
      background-color: ${colors.light};
      li {
        button {
          font-weight: bold;

          &.sub_item_menu {
            font-weight: normal;
            
            &.active {
              font-weight: bold !important;
            }

          }
        }
      }
    }
    & > button {
      background-color: ${colors.light};
      color: ${colors.disableGray};
    }
  }

  button.sub_item_menu {
    span {
      color: #444444 !important;
    }
  }
`;
