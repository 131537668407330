import styled from "styled-components";
import { SIDEBAR_WIDTH } from "../../../blackbook-components/quarks/sizes";
import { colors } from "../../../blackbook-components/quarks/theme";

export const Container = styled.div`
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? "grid" : "flex")};

  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;

  min-height: calc(100vh - 86px);
  height: calc(100vh - 86px);

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .contentOverlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.96);
    z-index: 60;
    height: calc(100% - 67px);
    width: calc(100vw - 240px);
    top: 50px;
  }
  .sideBarComponent {
    li > ul {
      z-index: 99;
    }
  }
`;

export const SidebarButtonsDiv = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SidebarButton = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 55px;
  border: none;
  background-color: ${({active}) => active ? colors.secondary : colors.primary};
  color: ${({active}) => active ? colors.black : colors.white};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  z-index: ${({active}) => active ? 9999 : 1};

  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`
