import styled from "styled-components";

export const BannerSection = styled.section`
  width: 100%;
  height: 100%;

  .loading__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .corner__text {
    position: absolute;
    background-color: var(--purple-500);
    border-radius: 10px 0 10px 0;
    padding: 10px;
    color: white;
    font-weight: 700;
  }

  .bannerLink {
    height: 100%;
    cursor: pointer;
  }
  .banner {
    position: relative;
    height: 100%;
    max-height: 350px;
    z-index: -10;
    border-radius: 10px;
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
    display: grid;
    grid-template-columns: 55% 1fr ;
  }
  
  .svg {
    position: absolute;
    height: 100%;
    max-width: 100%;
    width: auto;
    z-index: -1;
    right: 0;
  }
  
  .textArea {
    box-sizing: border-box;
    /* padding: 14px; */
    /* position: absolute; */
    left: 0;
    height: 100%;
    width: auto;
    display: grid;
    grid-template-columns: 9fr 1fr;
    position: relative;
  }
  
  .textArea > p {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-align: left;
    color: black;
    text-decoration: none;
    font-size: calc(13px + 1.5vw);
    line-height: calc(15px + 1.5vw);
    display: flex;
    align-items: center;

    background-color: white;
    max-width: 90%;
  }
  
  .banner__image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 60%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
  }
`;
