/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// @ts-ignore
import { CalculatorContainer, CalculatorTitle, Container, Main, ClearButton, TitleContainer, ContentContainer } from "./style";
// @ts-ignore
import { WebAppTemplate } from "../../components/templates/WebAppTemplate";
import CalculatorResults from "../../components/organisms/CalculatorResults";
import React, { LazyExoticComponent, Suspense, lazy, useEffect } from "react";
import { useState } from "react";
//@ts-ignore
import { BBLoadingGif } from "../../blackbook-components/quarks/blackbook-loading";

interface PrimaryResult {
  primary:
    | {
        result: any;
        primaryTitle?: string;
        primaryInterpretation?: string;
      }
    | undefined;
}

interface SecondaryResult {
  secondary?:
    | {
        title: string;
        result: any;
        interpretation?: string;
        table?: boolean;
        resultColor?: string;
        resultHasLink?: boolean;
      }[]
    | undefined;
}

export interface ContextType {
  setPrimaryResult: (e: PrimaryResult) => void;
  setSecondaryResults: (e: SecondaryResult) => void;
  primaryResult: PrimaryResult | undefined;
  secondaryResults?: SecondaryResult | undefined;
  canShowPrimary: boolean;
  canShowSecondary?: boolean;
  setCanShowPrimary: (e: boolean) => void;
  setCanShowSecondary?: (e: boolean) => void;
  color?: string;
  setColor?: (e: string) => void;
  initialState: boolean;
  setInitialState: (e: boolean) => void;
  reference: string;
  setReference: (e: string) => void;
  zeroAsValidResult?: boolean;
  setZeroAsValidResult?: (e: boolean) => void;
}

const CalculatorList = [
  {
    _id: "calc_PERC",
    title: "Critérios PERC de embolia pulmonar",
  },
  {
    _id: "calc_CKD",
    title: "Clearence de creatinina (CKD-EPI, 2021)",
  },
  {
    _id: "calc_CrCl",
    title: "Clearance de creatinina Cockcroft-Gault",
  },
  {
    _id: "calc_Schwartz",
    title: "Clearance de creatinina pediátrico (Schwartz)",
  },
  {
    _id: "calc_CURB",
    title: "CURB-65 - Escore de gravidade de pneumonia comunitária",
  },
  {
    _id: "calc_BirthDate",
    title: "Data do parto e idade gestacional",
  },
  {
    _id: "calc_Apgar",
    title: "Escala de Apgar",
  },
  {
    _id: "calc_Glasgow",
    title: "Escala de Glasgow",
  },
  {
    _id: "calc_Geneve",
    title: "Escore Genebra de embolia pulmonar (revisado) ",
  },
  {
    _id: "calc_GRACE",
    title: "Escore GRACE",
  },
  {
    _id: "calc_HEART",
    title: "Escore HEART",
  },
  {
    _id: "calc_Killip",
    title: "Escore Killip",
  },
  {
    _id: "calc_SOFA",
    title: "Escore SOFA",
  },
  {
    _id: "calc_WellsTEP",
    title: "Escore de Wells para embolia pulmonar",
  },
  {
    _id: "calc_WellsTVP",
    title: "Escore de Wells para trombose venosa profunda",
  },
  {
    _id: "calc_CFExercise",
    title: "Frequência cardíaca ideal nos exercícios",
  },
  {
    _id: "calc_IMC",
    title: "Índice de massa corporal (IMC) e análise do peso corporal",
  },
  {
    _id: "calc_MEEM",
    title: "MEEM- Mini-Exame do Estado Mental (Mini Mental)",
  },
  {
    _id: "calc_MEWS",
    title: "Escore MEWS (Modified Early Warning Score) de deterioração clínica",
  },
  {
    _id: "calc_MOCA",
    title: "MoCA - Montreal Cognitive Assessment",
  },
  {
    _id: "calc_NEWS",
    title: "Escore NEWS e NEWS2 (National Early Warning Score)",
  },
  {
    _id: "calc_Oakland",
    title:
      "Escore de Oakland (para definir alta segura em caso de hemorragia digestiva baixa)",
  },
  {
    _id: "calc_PAAS",
    title: "PASS - Pediatric Asthma Severity Score",
  },
  {
    _id: "calc_PESI",
    title: "PESI (Pulmonary Embolism Severity Index)",
  },
  {
    _id: "calc_PEWS",
    title: "Pediatric Early Warning Score (PEWS)",
  },
  {
    _id: "calc_PSI",
    title: "PSI ou IGP- Indice de gravidade de pneumonia comunitária",
  },
  {
    _id: "calc_BSA",
    title: "Superfície corporal",
  },
  {
    _id: "calc_Tc6m",
    title: "Teste da caminhada de 6 minutos",
  },
  {
    _id: "calc_TIMINStemi",
    title:
      "TIMI NSTEMI: Escore de risco TIMI para infarto sem elevação de ST ou angina instável",
  },
  {
    _id: "calc_TIMIStemi",
    title: "TIMI STEMI: Escore de risco TIMI para infarto com elevação de ST",
  },
  {
    _id: "calc_TIMIIndex",
    title: "TIMI TRI: Índice de risco TIMI",
  },
  {
    _id: "calc_WAT",
    title:
      "Escala WAT-1 (Withdrawas Assesment Tool 1) de abstinência em pediatria",
  },
  {
    _id: "calc_WoodDownes",
    title: "Escore de Wood-Downes-Ferres (gravidade da bronquiolite)",
  },
];

export const CalculatorContext = React.createContext<ContextType | undefined>(
  undefined
);

export function WebAppCalculators() {
  const { calc_name } = useParams();
  const [Calculator, setCalculator] = useState<LazyExoticComponent<any>>();
  const [primaryResult, setPrimaryResult] = useState<PrimaryResult>();
  const [secondaryResults, setSecondaryResults] = useState<SecondaryResult>();
  const [canShowPrimary, setCanShowPrimary] = useState(false);
  const [canShowSecondary, setCanShowSecondary] = useState(false);
  const [color, setColor] = useState("");
  const [reference, setReference] = useState("");
  const [initialState, setInitialState] = useState(true);
  const [zeroAsValidResult, setZeroAsValidResult] = useState(false);
  const loadCalculatorComponent = (calc_name: any) => {
    return lazy(() => import(`./${calc_name.replace("c", "C")}`));
  };

  const context = {
    primaryResult,
    setPrimaryResult,
    secondaryResults,
    setSecondaryResults,
    canShowPrimary,
    canShowSecondary,
    setCanShowPrimary,
    setCanShowSecondary,
    color,
    setColor,
    initialState,
    setInitialState,
    reference,
    setReference,
    zeroAsValidResult,
    setZeroAsValidResult,
  };

  useEffect(() => {
    setPrimaryResult(undefined);
    setSecondaryResults(undefined);
    setCanShowPrimary(false);
    setCanShowSecondary(false);
    setInitialState(!initialState);
    setZeroAsValidResult(false);
    setColor("");
  }, [calc_name]);

  const calcToImport = async () => {
    try {
      const module = loadCalculatorComponent(calc_name);
      setCalculator(module);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    calcToImport();
  }, [calc_name]);

  return (
    <WebAppTemplate>
      <CalculatorContext.Provider value={context}>
        <Main>
          <Container>
            <CalculatorContainer>
              <TitleContainer>
                <CalculatorTitle>
                  {CalculatorList.find((calc) => calc._id === calc_name)!.title}
                </CalculatorTitle>
              </TitleContainer>
              {Calculator && (
                <ContentContainer>
                  <Suspense fallback={<BBLoadingGif />}>
                    <Calculator />
                  </Suspense>
                </ContentContainer>
              )}
              <ClearButton onClick={() => setInitialState(!initialState)}>
                LIMPAR
              </ClearButton>
            </CalculatorContainer>
          </Container>
          <CalculatorResults key={calc_name} />
        </Main>
      </CalculatorContext.Provider>
    </WebAppTemplate>
  );
}
