import styled from 'styled-components';
import { borderRadius, fontSize, spacing } from '../../quarks/theme';

export const AvatarStyle = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
img {
    height: 30px;
    width: 30px;
    border-radius: ${borderRadius.circle};
    overflow: hidden;
    object-fit: cover;
}
span {
    margin-left: ${spacing.medium};
    font-size: ${fontSize.small};
    font-family: Open Sans;
    font-weight: 300;
    @media screen and (max-width: 420px) {
        display: none;
    }
}
`
