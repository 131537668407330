import styled from 'styled-components';
import { borderRadius, colors, fontFamily, fontSize, spacing } from '../../quarks/theme';

export const SearchResultCardStyle = styled.div`
   font-family: ${fontFamily.secondary};

   .search_result_card--header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #363636;
      ${props => props.itHasNoResults ? 
         `
            border-radius: 5px;
            color: #999
         ` :
            'border-radius: 5px 5px 0 0'};

      h3 {
         display: flex;
         align-items: center;
         padding: 0;
         margin: 0;
         font-family: ${fontFamily.primary};
         font-weight: bold;
         svg {
            margin-right: ${spacing.medium};
         }
         small {
            font-size: ${fontSize.small};
            font-weight: lighter;
            font-family: ${fontFamily.secondary};
            padding-left: ${spacing.medium};
         }
      }
   }

   .search_result_card--body {
      padding: 10px;
      background-color: ${colors.black300};

      > div:last-child {
         ${props => props.isLimited && `
         position: absolute;
         left: 30px;
         right: 15px;
         pointer-events: none;
         color: transparent;
         height: 30px;

         .search_result_card--item--sub_item {
            display: none;
         }
      `}
      }
   }

   .showMore__container {
      height: 40px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: ${colors.black400};
      position: relative;
      border-radius: 0 0 5px 5px;
      font-size: 12px;
      color: ${colors.primary};
      font-weight: bold;
      font-family: ${fontFamily.primary};
      cursor: pointer;
      box-shadow: 0px -8px 8px rgba(0, 0, 0, 0.26);
      z-index: 2;
      margin-top: 3px;
   }
`

export const SearchResultCardItemStyle = styled.div`
   background-color: ${colors.black350};
   border-radius: ${borderRadius.small};
   margin-bottom: ${spacing.medium};
   overflow: hidden;
   box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
   h4 , h5{
      margin: 0;
   }
   small {
      color: ${colors.gray};
   }

   &:last-child {
      margin-bottom: 0;
   }

   .search_result_card--item--header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 5px 15px;
      box-sizing: border-box;
      min-height: 40px;

      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 1;
      position: relative;

      .search_result_card--item--header--title {
         display: flex;
         flex-direction: column;
         gap: 2px;

         h4 {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
         }

         small {
            font-size: 11px;
         }
      }

      .search_result_card--item--header--icon {
         font-size: ${fontSize.xxxlarge};
         position: relative;
         cursor: pointer;
         color: ${props => props.isOpen ? colors.secondary : colors.primary};
         display: flex;
      }
   }
   .search_result_card--item--sub_item {
      display: ${props => props.isOpen ? 'block' : 'none'};
      background-color: ${colors.black325};
      cursor: pointer;
      transition: background-color 0.2s;

      .search_result_card--item--sub_item--title {
         padding: 5px 10px;
         border-bottom: 1px solid ${colors.darkGray};
         display: flex;
         flex-direction: column;
         justify-content: center;
         gap: 2px;
         min-height: 40px;
         box-sizing: border-box;
         h5 {
            font-size: 14px;
            line-height: 16px;
         }

         small {
            font-size: 11px;
         }
      }
      &:last-child {
         .search_result_card--item--sub_item--title {
            border-bottom: none;
         }
      }

      &:hover {
         background-color: #2f2f2f;
      }
   }
`
