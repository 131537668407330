export const FemaleIcon = (size = "50px") => (
  <svg
    fill="#fb7cb1"
    height="50px"
    width="50px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.249 40.249"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <path d="M26.875,27.999h-4.25v-4.646c5.315-1.15,9.312-5.887,9.312-11.542C31.937,5.298,26.638,0,20.125,0 S8.312,5.299,8.312,11.812c0,5.655,3.996,10.391,9.312,11.542v4.646h-4.25c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h4.25v4.75 c0,1.381,1.119,2.5,2.5,2.5c1.381,0,2.5-1.119,2.5-2.5v-4.75h4.25c1.381,0,2.5-1.119,2.5-2.5S28.256,27.999,26.875,27.999z M13.312,11.812C13.312,8.056,16.37,5,20.125,5c3.755,0,6.813,3.057,6.813,6.812c0,3.758-3.058,6.813-6.813,6.813 C16.37,18.625,13.312,15.567,13.312,11.812z"></path>{" "}
      </g>{" "}
    </g>
  </svg>
);
