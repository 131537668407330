import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardComponent } from '../../atoms/card';
import { SearchResultCardItemStyle, SearchResultCardStyle } from './style';
import { BBSearchChevron, BBVectorIcon } from '../../quarks/icons';
import { colors } from '../../quarks/theme';

export const SearchResultCardItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item) => props.onClick(item);

  const handleSubItemClick = (subItem, item) =>
    props.onSubItemClick(subItem, item);

  function handleGetColor(item) {
    const colorByType = {
      brand: '#2A7EC6',
      dosage: '#258138',
    };

    return item.color || colorByType[item?.type] || null;
  }

  function handleClickItem() {
    const hasSubItems = props.sub_items && props.sub_items.length > 0;

    if (hasSubItems) setIsOpen(!isOpen);

    props.onClick(props, hasSubItems);
  }

  return (
    <SearchResultCardItemStyle isOpen={isOpen}>
      <div
        className={`search_result_card--item--header --track-search-mark-${props.type}`}
        data-item-id={props.item_id}
        onClick={handleClickItem}
      >
        <div className="search_result_card--item--header--title">
          <h4>{props.title}</h4>
          <small>{props.legend}</small>
        </div>
        <div className="search_result_card--item--header--icon">
          {props.sub_items && props.sub_items.length > 0 ? (
            <BBSearchChevron
              isUpDirection={!isOpen}
              color={isOpen ? colors.secondary : colors.primary}
            />
          ) : (
            <BBVectorIcon />
          )}
        </div>
      </div>
      {props.sub_items && props.sub_items.length > 0 && (
        <div className="search_result_card--item--sub_items">
          {props.sub_items.map((sub_item) => (
            <div
              className={`search_result_card--item--sub_item --track-search-mark-${props.type}`}
              data-item-id={`${props.legend_id}${props.item_id}${sub_item.sub_item_id}`}
              style={{ borderLeft: `solid 5px ${handleGetColor(sub_item)}` }}
              onClick={() => handleSubItemClick(sub_item, props)}
            >
              <div className="search_result_card--item--sub_item--title">
                <h5>{sub_item.title}</h5>
                <small style={{ color: handleGetColor(sub_item) }}>
                  {sub_item.legend}
                </small>
              </div>
            </div>
          ))}
        </div>
      )}
    </SearchResultCardItemStyle>
  );
};

export const SearchResultCardComponent = ({
  title,
  icon,
  results,
  onItemClick,
  onSubItemClick,
  onShowMoreClick,
  showMoreItemStartsOpen,
}) => {
  const [isResultsLimited, setIsResultsLimited] = useState(
    showMoreItemStartsOpen ? false :
    results.data.length > 3 && true
  );

  return (
    <CardComponent padding="0" className="search_result_card">
      <SearchResultCardStyle
        isLimited={isResultsLimited}
        itHasNoResults={results.length === 0}
      >
        <div className="search_result_card--header">
          <h3>
            {icon}
            {title.toUpperCase()} <small>({results.length} resultados)</small>
          </h3>
        </div>
        {results.data.length > 0 && (
          <>
            <div className="search_result_card--body">
              {results.data
                .slice(0, isResultsLimited ? 4 : results.data.length)
                .map((result) => (
                  <SearchResultCardItem
                    key={result.id}
                    {...result}
                    onClick={onItemClick}
                    onSubItemClick={onSubItemClick}
                  />
                ))}
            </div>
            {results.data.length > 3 && (
              <div
                className="showMore__container"
                onClick={() => {
                  onShowMoreClick(isResultsLimited);
                  setIsResultsLimited(!isResultsLimited);
                }}
              >
                VER{' '}
                {isResultsLimited ? 'MAIS EM ' + title.toUpperCase() : 'MENOS'}
              </div>
            )}
          </>
        )}
      </SearchResultCardStyle>
    </CardComponent>
  );
};

SearchResultCardComponent.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      legend: PropTypes.string.isRequired,
      legend_id: PropTypes.string.isRequired,
      legend_type: PropTypes.string.isRequired,
      free_access: PropTypes.bool.isRequired,
      sub_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          section: PropTypes.string.isRequired,
          color: PropTypes.string,
          free_access: PropTypes.bool.isRequired,
        })
      ),
    })
  ),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onSeeMoreClick: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  onSubItemClick: PropTypes.func.isRequired,
};
