import styled from "styled-components";
import { colors, spacing } from "../../../blackbook-components/quarks/theme";

export const Specialties = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .specialties__title {
    margin-bottom: 10px;

    color: ${colors.white};
    font-weight: 700;
    font-size: 19px;
  }

  .specialties__carousel {
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: ${spacing.xlarge};
    max-width: ${(props) =>
      props.isSidebarOpen ? "calc(100vw - 280px)" : "calc(100vw - 40px)"};
    overflow-x: auto;
    overflow-y: hidden;
  }

  .specialtyContainer {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: ${spacing.xlarge};


    filter: brightness(0.7);
    transition: filter 0.2s;

    &:hover {
      filter: none;
    }

    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 1280px) {
      min-width: 100px;
      &:last-child {
        min-width: 120px;
      }
    }
  }

  .specialty {
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;

    &.free {
      padding: 20px;
      border-radius: 6px;
      width: 100%;
      background: linear-gradient(
        180deg,
        #8774ff 0%,
        #8774ff 50%,
        #5f51b3 100%
      );
      svg {
        width: 50%;
        height: 100%;
        @media screen and (max-width: 1280px){
          width: 80%;
        }
      }
    }

    &.seeAll {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        #8774ff 0%,
        #8774ff 50%,
        #5f51b3 100%
      );
      border-radius: 6px;
      color: ${colors.white};
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      svg {
        margin-left: 10px;
        margin-top: 1px;
        height: 100%;
        width: 15px;
        path {
          fill: ${colors.white};
        }
      }
    }
  }

  .specialty__cover {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
  }

  .specialty__title {
    height: 2rem;
    margin-top: 12px;
    color: ${colors.white};
    font-weight: 300;
    line-height: 13px;
    text-align: center;
    &.seeAll {
      color: transparent;
    }
  }
`;