import styled from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../quarks/sizes';
import { colors, fontSize, spacing } from '../../quarks/theme';

export const SidebarStyle = styled.ul`
box-sizing: border-box;
position: relative;
width: ${SIDEBAR_WIDTH}px;
background: ${colors.black300};
color: ${colors.gray};
padding: ${spacing.none} ${spacing.xlarge};
margin: 0;
list-style: none;

li {
    button {
        text-align: left;
        cursor: pointer;
        padding: ${spacing.medium} ${spacing.none};
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            display: flex;
            align-items: center;
            padding-right: ${spacing.large};
            svg {
                margin-right: ${spacing.medium};
            }
        }
        .lock{
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .sub_item_arrow {
            display: flex;
            align-items: center;
            height: 100%;
            color: ${colors.primary};
            padding-right: ${spacing.mlarge};
        }
        background-color: transparent;
        border: none;
        color: ${colors.gray};
        font-size: ${fontSize.medium};
        &.active {
            color: ${colors.secondary};
            font-weight: bold;
            &>.sub_item_arrow {
                color: ${colors.secondary};
            }
        }
    }
    border-top: 1px solid ${colors.darkGray};
    &:first-child {
        border-top: none;
    }
    &.active {
        border-color: ${colors.secondary};
    }
    &:last-child {
        border-bottom: none;
    }
    &.active + li {
        border-color: ${colors.secondary};
    }
}


&.sub_item_menu {
    display: block;
    width: max-content;
    position: fixed;
    white-space: nowrap;
    top: calc(${HEADER_HEIGHT}px + 10px);
    left: ${({hierarchy}) => `${((hierarchy * SIDEBAR_WIDTH) + (hierarchy * 10))}px`};
    padding: ${spacing.xxxlarge} 5px ${spacing.xxxlarge} ${spacing.xlarge};
    max-height: calc(100vh - ${FOOTER_HEIGHT + HEADER_HEIGHT}px - 20px);
    overflow-y: scroll;
    z-index: 999;

    width: ${SIDEBAR_WIDTH}px;
    word-break: break-word;
    white-space: pre-wrap;

    .sub_item_menu {
        margin-top: 0;
    }

    &.active {
        display: block;
    }

    li {
        button {   
            padding: ${spacing.small} ${spacing.none};
        }
        &:last-child {
            border-bottom: 1px solid ${colors.darkGray};
        }
        &:first-child {
            border-top: 1px solid ${colors.darkGray};
        }
        &.active {
            border-color: ${colors.secondary};
        }
    }
}
&.fullHeight {
    height: 100%;
    padding-bottom: 60px;
}
`
