import React, { useState } from "react";
import styled from "styled-components";
//@ts-ignore
import MainTheme from "../../../styles/MainTheme";
//@ts-ignore
import { FemaleIcon } from "../../../assets/icons/FemaleIcon.jsx";
//@ts-ignore
import { MaleIcon } from "../../../assets/icons/MaleIcon.jsx";
//@ts-ignore
import { ArrowDownIcon } from "../../../assets/icons/ArrowDown.jsx";

interface IsOpen {
  isOpen: boolean;
}

const CIDAccordionContainer = styled.div``;

const CIDAccordionTitleContainer = styled.button<IsOpen>`
  background-color: #333;
  padding: 15px;
  border-radius: ${({ isOpen }) => (!isOpen ? "10px" : "")};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CIDAccordionTitleSection = styled.div``;

const CIDAccordionContentContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 90px;
  background-color: #262626;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const CIDAccordionContentSection = styled.div``;

const Title = styled.h3`
  color: ${MainTheme.color.grayFont};
  font-weight: bold;
  font-size: 18px;
  text-align: left;
`;

const SubTitle = styled.h4`
  color: ${MainTheme.color.disabledSoft};
  font-size: 16px;
  text-align: left;
`;
//@ts-ignore
export const CidAccordion = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const deathColor = data.death === "SIM" ? "red" : "transparent";
  return (
    <CIDAccordionContainer>
      <CIDAccordionTitleContainer
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <CIDAccordionTitleSection>
          <Title>{data.title}</Title>
          <SubTitle>{data.subTitle}</SubTitle>
        </CIDAccordionTitleSection>
        <CIDAccordionTitleSection>
          <ArrowDownIcon rotate={isOpen ? "rotate(0)" : "rotate(180)"} />
        </CIDAccordionTitleSection>
      </CIDAccordionTitleContainer>
      {isOpen && (
        <CIDAccordionContentContainer>
          <CIDAccordionContentSection>
            <Title>Classificação: {data.classification}</Title>
            <Title style={{marginTop: 5}}>
              Causa óbito:{" "}
              <b style={{ backgroundColor: deathColor }}>{data.death}</b>
            </Title>
          </CIDAccordionContentSection>
          <CIDAccordionContentSection>
            {data.gender === "F" ? (
              <FemaleIcon />
            ) : data.gender === "M" ? (
              <MaleIcon />
            ) : (
              <>
                <FemaleIcon />
                <MaleIcon />
              </>
            )}
          </CIDAccordionContentSection>
        </CIDAccordionContentContainer>
      )}
    </CIDAccordionContainer>
  );
};
